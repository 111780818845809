import React from 'react';

import StatusCircle from 'components/StatusCircle';

type Status = 'error' | 'warning' | 'success' | 'all';

type Props = {
  status: Status;
};

const ImportStatusBadge: React.FC<Props> = ({ status }: Props) => {
  const getBadgeColor = (s: Status): string => {
    switch (s) {
      case 'error':
        return 'red';
      case 'warning':
        return 'orange';
      case 'success':
        return 'green';
      case 'all':
        return 'gray';
      default:
        return 'gray';
    }
  };

  const getBadgeDescription = (s: Status): string => {
    switch (s) {
      case 'error':
        return 'Erro';
      case 'warning':
        return 'Aviso';
      case 'success':
        return 'Sucesso';
      default:
        return 'Não definido';
    }
  };

  const color = getBadgeColor(status);
  const description = getBadgeDescription(status);

  return (
    <StatusCircle hasCircle={false} colorScheme={color}>
      {description}
    </StatusCircle>
  );
};

export default ImportStatusBadge;
