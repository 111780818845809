import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Filter as FilterIcon } from '@styled-icons/fa-solid/Filter';
import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Button,
  Icon,
  Grid,
  FormControl,
  FormLabel,
  Flex,
  FormErrorMessage,
  Input,
} from '@chakra-ui/react';

import OwnSelect from 'components/OwnSelect';

import { UnitMeasure } from 'types/base';
import { Budget } from 'types/budget';

interface Qs {
  [key: string]: string | number | null | undefined;
}

export interface FilterHandles {
  open: () => void;
}

export interface FilterData extends Qs {
  base_id: number | null;
  version_id: number | null;
  locale_id: number | null;
  type_id: number | null;
  classification_id: number | null;
  classification_type_id: number | null;
  unit_measure_id: number | null;
  description: string;
}

interface FormProps {
  bases: Budget['bases'];
  onSubmit(data: FilterData): void;
  loading?: boolean;
}

const SearchFilter: React.FC<FormProps> = ({
  bases: budgetBases,
  onSubmit,
  loading = false,
}) => {
  const {
    handleSubmit,
    setValue,
    register,
    control,
    formState: { errors },
  } = useForm<FilterData>();

  const [selectedBase, setSelectedBase] = useState<number | null>(null);
  const [selectedBaseVersion, setSelectedBaseVersion] = useState<number | null>(
    null,
  );
  const [selectedBaseLocale, setSelectedBaseLocale] = useState<number | null>(
    null,
  );
  const [selectedBasePriceType, setSelectedBasePriceType] = useState<
    number | null
  >(null);

  const [unitMeasures, setUnitMeasures] = useState<UnitMeasure[]>([]);
  const [selectedUnitMeasure, setSelectedUnitMeasure] = useState<number | null>(
    null,
  );
  const [isLoadingUnitMeasures, setIsLoadingUnitMeasures] = useState(false);

  const prepareSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data,
      });
    },
    [onSubmit],
  );

  const basesToSelect = useMemo(() => {
    return budgetBases.map((base) => ({
      value: String(base.version.base.id),
      label: base.version.base.description,
    }));
  }, [budgetBases]);

  const getUnitMeasures = useCallback(async () => {
    setIsLoadingUnitMeasures(true);

    try {
      const { data: response } = await api.get('/unit/measure/list');
      const unitMeasuresLoaded = response.data;
      setUnitMeasures(unitMeasuresLoaded);
    } catch (err) {
      toast({
        description: 'Houve um erro ao carregar as unidades de medida!',
        status: 'error',
      });
    } finally {
      setIsLoadingUnitMeasures(false);
    }
  }, []);

  const unitMeasuresToSelect = useMemo(() => {
    return unitMeasures.map((unitMeasure) => ({
      value: String(unitMeasure.id),
      label: unitMeasure.description,
    }));
  }, [unitMeasures]);

  useEffect(() => {
    getUnitMeasures();
  }, [getUnitMeasures]);

  type Selected = { value: string } | null;
  const handleBaseChange = useCallback(
    (selected: Selected): void => {
      const value = selected ? Number(selected.value) : null;

      setSelectedBase(value);
      setValue('base_id', value);

      const baseSelected = budgetBases.find(
        (base) => base.version.base.id === value,
      );

      if (baseSelected) {
        setSelectedBaseVersion(baseSelected.version.id);
        setValue('version_id', baseSelected.version.id);

        setSelectedBaseLocale(baseSelected.locale.id);
        setValue('locale_id', baseSelected.locale.id);

        setSelectedBasePriceType(baseSelected.price_type.id);
        setValue('price_type_id', baseSelected.price_type.id);
      }
    },
    [budgetBases, setValue],
  );

  useEffect(() => {
    const { base } = budgetBases[0].version;

    if (base) {
      handleBaseChange({ value: String(base.id) });
    }
  }, [budgetBases, handleBaseChange]);

  const submitForm = (): void => {
    handleSubmit(prepareSubmit)();
  };

  const versionDescription = budgetBases.find(
    (base) => base.version.id === selectedBaseVersion,
  )?.version.description;

  const localeDescription = budgetBases.find(
    (base) => base.locale.id === selectedBaseLocale,
  )?.locale.description;

  const priceTypeDescription = budgetBases.find(
    (base) => base.price_type.id === selectedBasePriceType,
  )?.price_type.description;

  return (
    <Flex
      w="100%"
      as="form"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{
        '> *': {
          marginY: 1,
        },
      }}
      onSubmit={handleSubmit(prepareSubmit)}
    >
      <Grid
        w="100%"
        templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        columnGap={2}
        rowGap={4}
      >
        <FormControl isInvalid={!!errors.base_id}>
          <FormLabel>Base de dados</FormLabel>

          <Controller
            render={(field) => (
              <OwnSelect
                {...field}
                placeholder="Selecione"
                options={basesToSelect}
                value={basesToSelect.filter(
                  (s) => Number(s.value) === selectedBase,
                )}
                isDisabled={basesToSelect.length === 0}
                onChange={handleBaseChange}
              />
            )}
            name="base_id"
            control={control}
            rules={{ required: true }}
          />

          <FormErrorMessage>
            {errors.base_id && 'Base é obrigatória.'}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.version_id}>
          <FormLabel>Referência</FormLabel>

          <Input
            value={versionDescription}
            isReadOnly
            _readOnly={{ background: 'white' }}
          />

          <FormErrorMessage>
            {errors.version_id && 'Referência é obrigatória.'}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.locale_id}>
          <FormLabel>Estado</FormLabel>

          <Input
            value={localeDescription}
            isReadOnly
            _readOnly={{ background: 'white' }}
          />

          <FormErrorMessage>
            {errors.locale_id && 'Estado é obrigatório.'}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.type_id}>
          <FormLabel>Tipo de preço</FormLabel>

          <Input
            value={priceTypeDescription}
            isReadOnly
            _readOnly={{ background: 'white' }}
          />

          <FormErrorMessage>
            {errors.type_id && 'Tipo é obrigatório.'}
          </FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>Unidade de medida</FormLabel>

          <Controller
            render={(field) => (
              <OwnSelect
                {...field}
                placeholder="Selecione"
                maxMenuHeight={200}
                options={unitMeasuresToSelect}
                value={unitMeasuresToSelect.filter(
                  (s) => Number(s.value) === selectedUnitMeasure,
                )}
                isLoading={isLoadingUnitMeasures}
                isDisabled={
                  isLoadingUnitMeasures || unitMeasuresToSelect.length === 0
                }
                isClearable
                onChange={(selected) => {
                  const value = selected ? Number(selected.value) : null;

                  setSelectedUnitMeasure(value);
                  setValue('unit_measure_id', value);
                }}
              />
            )}
            name="unit_measure_id"
            control={control}
          />
        </FormControl>
      </Grid>

      <Grid
        w="100%"
        templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        columnGap={2}
        rowGap={4}
        marginTop="4"
      >
        <FormControl>
          <FormLabel>Pesquisar por descrição / código da composição</FormLabel>
          <Input {...register('description')} />
        </FormControl>
      </Grid>

      <Button
        w="100%"
        type="submit"
        colorScheme="blue"
        isLoading={loading}
        leftIcon={<Icon as={FilterIcon} />}
        onClick={submitForm}
        mt="4"
        mb="4"
      >
        Filtrar
      </Button>
    </Flex>
  );
};

export default SearchFilter;
