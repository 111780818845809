import React, {
  Suspense,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import InputNumberFormat from 'react-number-format';

import { ArrowRight as ArrowRightIcon } from '@styled-icons/bootstrap/ArrowRight';
import { Check as CheckIcon } from '@styled-icons/boxicons-regular/Check';
import { TrashAlt as TrashAltIcon } from '@styled-icons/boxicons-solid/TrashAlt';
import { Close as CloseIcon } from '@styled-icons/evaicons-solid/Close';
import { api } from 'services/api';
import { isAxiosError } from 'services/axios';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
  Icon,
  IconButton,
  Input,
  Text,
  Box,
  TableContainer,
  useMediaQuery,
  Select,
  HStack,
  Progress,
} from '@chakra-ui/react';

import AppTable from 'components/AppTable';
import Loader from 'components/Loader';
import { ModalRootProps } from 'components/Modal/Root';
import PaginationWrapper from 'components/Pagination';
import { Table, Tbody, Td, Th, Thead, Tr } from 'components/Table';

import useThrottledState from 'hooks/useThrottledState';

import { Budget } from 'types/budget';
import {
  Composition,
  State,
  DefaultPrice,
  StatePrice,
} from 'types/composition';
import { Pagination, ServicePagination } from 'types/pagination';

import SearchFilter, { FilterData } from './SearchFilter';

interface ModalCompositionSelect extends ModalRootProps {
  data: {
    bases: Budget['bases'];
    subStageId: number;
    budgetId: number;
  };
}

type Component = {
  type: 'composition_original';
  quantity: number;
  composition_id: number;
  budget_base_id: number;
};

type ComponentData = {
  parent_stage_id: number;
  components: Component[];
};

type SelectedComposition = Composition & {
  quantity: number | undefined;
};

const modalMargin = 120;
const modalMobileFix = 100;

function isDefaultPrice(price: StatePrice): price is DefaultPrice {
  return 'default' in price;
}

const ModalBudgetCompositionSelect: React.FC<ModalCompositionSelect> = ({
  data: { budgetId, bases, subStageId },
  handleClose,
  onConfirm,
  ...restProps
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isMobile] = useMediaQuery('(max-width: 728px)');

  const [saving, setSaving] = useState(false);
  const [fakeProgress, setFakeProgress] = useState(0);

  const [compositions, setCompositions] = useState<Composition[]>([]);
  const [pagination, setPagination] = useThrottledState<Pagination>(
    (() => {
      return {
        per_page: 20,
        page: 1,
      };
    })(),
    1000,
  );

  const [searchFilter, setSearchFilter] = useState({} as FilterData);
  const [sort, setSort] = useState('description');

  const hasRequiredFilters = useMemo(() => {
    return (
      !!searchFilter.base_id &&
      !!searchFilter.version_id &&
      !!searchFilter.locale_id &&
      !!searchFilter.price_type_id
    );
  }, [
    searchFilter.base_id,
    searchFilter.version_id,
    searchFilter.locale_id,
    searchFilter.price_type_id,
  ]);

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const [selectedCompositions, setSelectedCompositions] = useState<
    SelectedComposition[]
  >([]);

  const [loading, setLoading] = useState(false);

  const handleTabsChange = (index: number): void => {
    setActiveTab(index);
  };

  const processFilters = useCallback((filter: FilterData) => {
    const validFilter = {};

    if (filter.description) {
      Object.assign(validFilter, { 'filter[keyword]': filter.description });
    }

    if (filter.base_id) {
      Object.assign(validFilter, { 'filter[base_id]': filter.base_id });
    }

    if (filter.version_id) {
      Object.assign(validFilter, { 'filter[version_id]': filter.version_id });
    }

    if (filter.locale_id) {
      Object.assign(validFilter, { 'filter[locale_id]': filter.locale_id });
    }

    if (filter.price_type_id) {
      Object.assign(validFilter, {
        'filter[price_type_id]': filter.price_type_id,
      });
    }

    if (filter.classification_id) {
      Object.assign(validFilter, {
        'filter[classification_id]': filter.classification_id,
      });
    }

    if (filter.classification_type_id) {
      Object.assign(validFilter, {
        'filter[type_id]': filter.classification_type_id,
      });
    }

    if (filter.unit_measure_id) {
      Object.assign(validFilter, {
        'filter[unit_measure_id]': filter.unit_measure_id,
      });
    }

    return validFilter;
  }, []);

  const getPrice = (composition: Composition): number => {
    const price = getStatePrice(composition);

    if (isDefaultPrice(price)) {
      return price.default.total;
    }

    if (price.taxed) {
      return price.taxed.total;
    }

    if (price.untaxed) {
      return price.untaxed.total;
    }

    return 0;
  };

  const processSort = useCallback(
    (sortString: string | null): Record<string, string> => {
      if (!sortString) return Object.create({});

      const isDesc = sortString.startsWith('-');
      const field = isDesc ? sortString.slice(1) : sortString;
      const mode = isDesc ? 'desc' : 'asc';

      return { 'sort[field]': field, 'sort[mode]': mode };
    },
    [],
  );

  const getData = useCallback(async () => {
    if (!hasRequiredFilters) return;

    setLoading(true);
    setCompositions([]);

    try {
      const response = await api.get('/composition', {
        params: {
          'filter[resource]': 'composition',
          ...processFilters(searchFilter),
          ...processSort(sort),
          page: pagination.page,
          per_page: pagination.per_page,
        },
      });

      const compositionsData = response.data;

      const newPagination = {
        last_page: compositionsData.meta
          ? compositionsData.meta.last_page
          : compositionsData.last_page,
      };

      setCompositions(compositionsData.data);
      setServicePagination(newPagination);
    } catch (err) {
      toast({
        status: 'error',
        description:
          err.response?.data?.message || 'Houve um erro ao buscar composições',
      });

      setCompositions([]);
      setServicePagination({ last_page: 1 });
    } finally {
      setLoading(false);
    }
  }, [
    pagination,
    searchFilter,
    processSort,
    processFilters,
    sort,
    hasRequiredFilters,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleSelectComposition = (item: Composition): void => {
    setSelectedCompositions((prepared) => [
      ...prepared,
      {
        ...item,
        quantity: 1,
      },
    ]);
  };

  const handleDeleteComposition = (item: Composition): void => {
    setSelectedCompositions((selected) =>
      selected.filter((c) => c._id !== item._id),
    );
  };

  const getStatePrice = (composition: Composition): State => {
    if (!composition._source.prices) {
      return {
        default: {
          total: 0,
        },
      } as State;
    }

    const states = Array.from(Object.keys(composition._source.prices));
    const state = states[0];

    return composition._source.prices[state];
  };

  const prepareComponents = useCallback(
    (selected: SelectedComposition[]): ComponentData => {
      return {
        parent_stage_id: subStageId,
        components: selected.map((item) => ({
          type: 'composition_original',
          composition_id: Number(item._id),
          quantity: item.quantity || 0,
          budget_base_id:
            bases.find(
              (b) => b.version.base.id === item._source.version.base.id,
            )?.id || 0,
        })),
      };
    },
    [subStageId, bases],
  );

  const handleSubmitComponents = useCallback(async () => {
    setLoading(true);
    setSaving(true);
    setFakeProgress(0);

    try {
      await api.post(`budget/${budgetId}/component`, {
        ...prepareComponents(selectedCompositions),
      });

      toast({
        description: 'Composições salvas com sucesso!',
        status: 'success',
      });

      setFakeProgress(99);

      setTimeout(() => {
        if (onConfirm) onConfirm();
        handleClose();
      }, 1000);
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          description:
            err.response?.data?.message ||
            'Houve um erro ao salvar as composições selecionadas.',
          status: 'error',
        });
      }
    } finally {
      setSaving(false);
      setLoading(false);
    }
  }, [
    selectedCompositions,
    prepareComponents,
    budgetId,
    handleClose,
    onConfirm,
  ]);

  useEffect(() => {
    if (saving) {
      const interval = setInterval(() => {
        setFakeProgress((oldProgress) => {
          if (oldProgress === 90) {
            return oldProgress;
          }

          if (oldProgress === 99.9) {
            // wait 1 second to close modal
            setTimeout(() => {
              setFakeProgress(100);
            }, 1000);

            clearInterval(interval);
            return 0;
          }

          return Math.min(oldProgress + 10, 100);
        });
      }, 3000);

      return () => clearInterval(interval);
    }

    return undefined;
  }, [saving]);

  useEffect(() => {
    if (pagination.page > servicePagination?.last_page) {
      setPagination((oldPagination) => {
        if (oldPagination.page > 1) {
          return {
            ...oldPagination,
            page: 1,
          };
        }

        return oldPagination;
      });
    }
  }, [pagination.page, servicePagination, setPagination]);

  return (
    <Modal {...restProps} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        sx={{
          width: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          maxWidth: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          height: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          maxHeight: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
        }}
      >
        <ModalHeader>Selecionar composições</ModalHeader>

        <ModalCloseButton />

        {!isMobile && (
          <Button
            position="absolute"
            right="50px"
            top="10px"
            colorScheme="green"
            rightIcon={
              activeTab === 0 ? <Icon as={ArrowRightIcon} /> : undefined
            }
            leftIcon={activeTab === 1 ? <Icon as={CheckIcon} /> : undefined}
            isLoading={loading}
            isDisabled={
              (activeTab === 1 && selectedCompositions.length === 0) || loading
            }
            onClick={() => {
              if (activeTab === 0) {
                setActiveTab(1);
              } else {
                handleSubmitComponents();
              }
            }}
          >
            {activeTab === 0 ? 'Avançar' : 'Salvar'}
          </Button>
        )}

        <ModalBody>
          {fakeProgress > 0 && fakeProgress <= 100 && (
            <>
              <Text fontWeight="bold" fontSize="md">
                Salvando composições...
              </Text>

              <Progress
                hasStripe
                isAnimated
                value={fakeProgress === 99 ? 100 : fakeProgress}
                my={4}
                borderRadius="3px"
              />
            </>
          )}

          <Tabs
            variant="enclosed"
            colorScheme="red"
            index={activeTab}
            onChange={handleTabsChange}
          >
            <TabList>
              <Tab _selected={{ fontWeight: 'bold' }}>Pesquisar</Tab>
              <Tab _selected={{ fontWeight: 'bold' }}>
                Selecionadas{' '}
                {selectedCompositions.length > 0 && (
                  <Box
                    width={4}
                    height={4}
                    marginX={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    backgroundColor="red.600"
                    fontSize="10px"
                    fontWeight="bold"
                    color="white"
                    borderRadius="50%"
                  >
                    {selectedCompositions.length}
                  </Box>
                )}
              </Tab>
            </TabList>

            <Suspense fallback={<Loader />}>
              <TabPanels>
                <TabPanel p={0} pt={4}>
                  <SearchFilter
                    bases={bases}
                    onSubmit={(data) => setSearchFilter(data)}
                    loading={loading}
                  />

                  <HStack mb={3}>
                    <Text fontSize="smaller">Por página:</Text>
                    <Select
                      width="auto"
                      defaultValue={pagination.per_page}
                      onChange={(e) => {
                        setPagination({
                          ...pagination,
                          per_page: Number(e.target.value),
                        });
                      }}
                    >
                      {[5, 10, 20, 50, 100].map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </Select>
                  </HStack>

                  <AppTable
                    cols={[
                      { field: 'code', description: 'Código' },
                      { field: 'description', description: 'Descrição' },
                      {
                        field: 'unit_measure',
                        description: 'Unidade',
                        sortable: false,
                      },
                      {
                        field: 'prices',
                        description: 'Valor unitário',
                        isNumeric: true,
                      },
                      {
                        field: 'actions',
                        description: 'Ações',
                        isCentered: true,
                      },
                    ]}
                    mapping={(item) => ({
                      id: item._id,
                      code: item._source.code,
                      description: (
                        <Text
                          py={1}
                          maxWidth={{ base: '100%', md: '650px' }}
                          whiteSpace="pre-wrap"
                        >
                          {item._source.description}
                        </Text>
                      ),
                      unit_measure: item._source.unit_measure.description,
                      prices: Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(getPrice(item)),
                      actions: (
                        <HStack spacing={2}>
                          <Button
                            colorScheme="green"
                            leftIcon={<Icon as={CheckIcon} />}
                            onClick={() => handleSelectComposition(item)}
                            disabled={selectedCompositions.some(
                              (c) => c._id === item._id,
                            )}
                          >
                            {selectedCompositions.some(
                              (c) => c._id === item._id,
                            )
                              ? 'Selecionado'
                              : 'Selecionar'}
                          </Button>

                          {selectedCompositions.some(
                            (c) => c._id === item._id,
                          ) && (
                            <IconButton
                              aria-label="Remover composição"
                              colorScheme="red"
                              icon={<Icon as={CloseIcon} />}
                              onClick={() => handleDeleteComposition(item)}
                            />
                          )}
                        </HStack>
                      ),
                      active: selectedCompositions.some(
                        (c) => c._id === item._id,
                      ),
                    })}
                    data={compositions}
                    loading={loading}
                    initialSort={sort}
                    onUpdateSort={(s) => setSort(s)}
                  />

                  <PaginationWrapper
                    lastPage={servicePagination.last_page}
                    onPaginate={(selectedPage) => {
                      setPagination({ ...pagination, page: selectedPage });
                    }}
                  />
                </TabPanel>

                <TabPanel p={0} pt={4}>
                  <TableContainer>
                    <Table>
                      <Thead>
                        <Tr inHeader>
                          <Th>Código</Th>
                          <Th>Descrição</Th>
                          <Th>Unidade</Th>
                          <Th isNumeric>Quantidade</Th>
                          <Th isNumeric>Valor unitário</Th>
                          <Th>Ações</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {selectedCompositions.length > 0 ? (
                          selectedCompositions.map((item, i) => (
                            <Tr inHeader={false} key={item._id}>
                              <Td columnKey={i}>{item._source.code}</Td>
                              <Td columnKey={i}>
                                <Text
                                  py={1}
                                  maxWidth={{ base: '100%', md: '650px' }}
                                  whiteSpace="pre-wrap"
                                >
                                  {item._source.description}
                                </Text>
                              </Td>
                              <Td columnKey={i}>
                                {item._source.unit_measure.description}
                              </Td>
                              <Td columnKey={i}>
                                <InputNumberFormat
                                  type="tel"
                                  className="text-right"
                                  displayType="input"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  allowNegative={false}
                                  decimalScale={4}
                                  value={item.quantity}
                                  onValueChange={(v) => {
                                    setSelectedCompositions((selected) =>
                                      selected.map((composition) =>
                                        composition._id === item._id
                                          ? {
                                              ...composition,
                                              quantity: v.floatValue,
                                            }
                                          : composition,
                                      ),
                                    );
                                  }}
                                  customInput={Input}
                                />
                              </Td>
                              <Td columnKey={i} isNumeric>
                                {Intl.NumberFormat('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                }).format(getPrice(item))}
                              </Td>
                              <Td columnKey={i}>
                                <Button
                                  tabIndex={-1}
                                  colorScheme="red"
                                  leftIcon={<Icon as={TrashAltIcon} />}
                                  onClick={() => handleDeleteComposition(item)}
                                >
                                  Remover
                                </Button>
                              </Td>
                            </Tr>
                          ))
                        ) : (
                          <Tr inHeader={false}>
                            <Td
                              columnKey={1}
                              colSpan={1000}
                              className="text-center"
                              paddingY={4}
                            >
                              Nenhuma composição selecionada
                            </Td>
                          </Tr>
                        )}
                      </Tbody>
                    </Table>
                  </TableContainer>

                  {isMobile && (
                    <Button
                      w="100%"
                      type="submit"
                      colorScheme="green"
                      isLoading={loading}
                      isDisabled={
                        selectedCompositions.length === 0 || loading || saving
                      }
                      leftIcon={<Icon as={CheckIcon} />}
                      onClick={handleSubmitComponents}
                      mt="4"
                    >
                      Salvar
                    </Button>
                  )}
                </TabPanel>
              </TabPanels>
            </Suspense>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalBudgetCompositionSelect;
