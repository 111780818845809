import React from 'react';
import ReactPaginate from 'react-paginate';

import { AngleLeft as AngleLeftIcon } from '@styled-icons/fa-solid/AngleLeft';
import { AngleRight as AngleRightIcon } from '@styled-icons/fa-solid/AngleRight';

import { Flex, Icon } from '@chakra-ui/react';

type PaginationProps = {
  lastPage: number;
  forcePage?: number;
  onPaginate(selectedPage: number): void;
  useAlternativeColors?: boolean;
};

const Pagination: React.FC<PaginationProps> = ({
  forcePage,
  lastPage,
  onPaginate,
  useAlternativeColors = false,
}) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      my={3}
      sx={{
        '& ul.pagination': {
          boxSizing: 'border-box',
          listStyle: 'none',
          display: 'flex',
          justifyContent: 'center',

          '& li': {
            userSelect: 'none',
            color: '#ffffff',
            direction: 'ltr',
            listStyle: 'none',
            boxSizing: 'border-box',
            cursor: 'pointer',
            backgroundColor: 'rgba(44, 44, 44, 0.08)',
            borderRadius: '0.5rem',
            margin: '0 0.2rem',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            '&:not(.active)': {
              color: '#626262',
            },

            '&:not(.active):hover': {
              backgroundColor: 'rgba(44, 44, 44, 0.13)',
            },

            '& a': {
              textDecoration: 'none',
              color: 'var(--ecustos-colors-black-100)',

              padding: '0.5rem',

              fontWeight: 'bold',
            },

            '&.active': {
              background: 'brand.400',

              '& a': {
                color: 'var(--ecustos-colors-black-400)',
              },
            },
          },

          '&.previous': {
            marginRight: '0.4rem',
          },

          '&.next': {
            marginLeft: '0.4rem',
          },
        },
        '& ul.pagination-fragment': {
          boxSizing: 'border-box',
          listStyle: 'none',
          display: 'flex',
          justifyContent: 'center',

          '& li': {
            userSelect: 'none',
            color: '#ffffff',
            direction: 'ltr',
            listStyle: 'none',
            boxSizing: 'border-box',
            cursor: 'pointer',
            backgroundColor: 'white',
            borderRadius: '0.5rem',
            margin: '0 0.2rem',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            '&:not(.active)': {
              color: '#626262',
            },

            '&:not(.active):hover': {
              backgroundColor: '#fffffff0',
            },

            '& a': {
              textDecoration: 'none',
              color: 'var(--ecustos-colors-black-100)',

              padding: '0.5rem',

              fontWeight: 'bold',
            },

            '&.active': {
              background: 'brand.400',

              '& a': {
                color: 'var(--ecustos-colors-black-400)',
              },
            },
          },

          '&.previous': {
            marginRight: '0.4rem',
          },

          '&.next': {
            marginLeft: '0.4rem',
          },
        },
      }}
    >
      <ReactPaginate
        previousLabel={<Icon as={AngleLeftIcon} />}
        nextLabel={<Icon as={AngleRightIcon} />}
        breakLabel="..."
        breakClassName="break-me"
        pageCount={lastPage}
        forcePage={forcePage}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={({ selected }) => {
          onPaginate(selected + 1);
        }}
        containerClassName={
          useAlternativeColors ? 'pagination-fragment' : 'pagination'
        }
        activeClassName="active"
      />
    </Flex>
  );
};

export default Pagination;
