import React, { useRef } from 'react';
import { ColumnInstance } from 'react-table';

import { Tr, Td, TableRowProps } from '@chakra-ui/react';

import { FragmentResource, Row } from './types';

interface RowProps {
  visibleColumns: ColumnInstance<FragmentResource>[];
  renderRowSubComponent: (params: {
    nextComponentId: number;
  }) => React.ReactElement;
  row: Row;
}

const FragmentTableRow: React.FC<RowProps> = ({
  visibleColumns,
  renderRowSubComponent,
  row,
  ...restProps
}) => {
  const ref = useRef<HTMLTableRowElement>(null);

  function getType(tableRow: Row): string {
    return tableRow.original.fragment_type;
  }

  function isComposition(tableRow: Row): boolean {
    return getType(tableRow).includes('composition');
  }

  function getCustomRowExpandedProps(tableRow: Row): TableRowProps {
    return {
      ...row.getToggleRowExpandedProps(),
      title: 'Detalhar composição',
      backgroundColor: tableRow.isExpanded ? 'brand.500' : 'inherit',
      color: tableRow.isExpanded ? 'white' : 'gray.800',
      _hover: {
        color: tableRow.isExpanded ? 'white' : 'gray.800',
        backgroundColor: tableRow.isExpanded ? 'brand.500' : 'gray.200',
      },
    };
  }

  return (
    <>
      <Tr
        ref={ref}
        {...row.getRowProps()}
        {...(getType(row) === 'composition' && getCustomRowExpandedProps(row))}
        {...restProps}
      >
        {row.cells.map((cell) => {
          return (
            <Td
              {...cell.getCellProps()}
              isNumeric={cell.column.isNumeric}
              borderBottomColor={row.isExpanded ? 'brand.400' : undefined}
              py={4}
            >
              {cell.render('Cell')}
            </Td>
          );
        })}
      </Tr>

      {row.isExpanded ? (
        <Tr
          backgroundColor={row.isExpanded ? 'brand.100' : 'inherit'}
          border="1px solid white"
          sx={{
            '* > table': {
              backgroundColor: 'white',
              borderBottom: 'none',
            },
          }}
        >
          <Td colSpan={visibleColumns.length} paddingY={4} borderBottom="none">
            {isComposition(row) &&
              renderRowSubComponent({
                nextComponentId: row.original.fragment.id,
              })}
          </Td>
        </Tr>
      ) : null}
    </>
  );
};

export default FragmentTableRow;
