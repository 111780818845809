import React from 'react';

import { Grid, GridProps } from '@chakra-ui/react';

interface SummaryContainerProps extends GridProps {
  loading: boolean;
}

const SummaryContainer = (props: SummaryContainerProps): React.ReactElement => {
  const { children, loading, ...rest } = props;

  return (
    <Grid
      width="100%"
      marginBottom="4"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection={{ base: 'column', lg: 'row' }}
      gap="3"
      {...rest}
    >
      {children &&
        React.Children.map(children, (child, idx) => {
          return (
            React.isValidElement(child) &&
            React.cloneElement(child, { idx, loading })
          );
        })}
    </Grid>
  );
};

export default SummaryContainer;
