import React, { useState, useEffect, useCallback, useContext } from 'react';

import { Check as CheckIcon } from '@styled-icons/boxicons-regular/Check';
import { api } from 'services/api';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  HStack,
  Flex,
  Input,
  Select,
  Button,
  Icon,
  Text,
} from '@chakra-ui/react';

import AppTable from 'components/AppTable';
import { ModalRootProps } from 'components/Modal/Root';
import PaginationWrapper from 'components/Pagination';

import AuthContext from 'contexts/AuthContext';

import useDebouncedState from 'hooks/useDebouncedState';
import useThrottledState from 'hooks/useThrottledState';

import { Client } from 'types/client';
import { Pagination, ServicePagination } from 'types/pagination';

const ModalClientLookup: React.FC<ModalRootProps> = ({
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const { user } = useContext(AuthContext);

  const [clientList, setClientList] = useState<Client[]>([]);
  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useDebouncedState<string>('');
  const [pagination, setPagination] = useThrottledState<Pagination>(
    (() => {
      return {
        per_page: 10,
        page: 1,
      };
    })(),
    1000,
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const getClientList = useCallback(async () => {
    setLoading(true);
    setClientList([]);

    try {
      const response = await api.get('/client', {
        params: {
          'filter[keyword]': keyword,
          page: pagination.page,
          per_page: pagination.per_page,
          'filter[organization_id]': user.managed_organization.organization.id,
        },
      });

      const clients = response.data;

      const newPagination = {
        last_page: clients.meta.last_page,
      };

      setClientList(clients.data);
      setServicePagination(newPagination);
    } catch (err) {
      setClientList([]);
      setServicePagination({ last_page: 1 });
    } finally {
      setLoading(false);
    }
  }, [keyword, pagination, user]);

  useEffect(() => {
    getClientList();
  }, [getClientList]);

  const clientNameByType = (client: Client): React.ReactNode => {
    if (client.type_person === 'legal') {
      return (
        <>
          {client.fantasy_name}
          <br />
          <small>{client.corporate_name}</small>
        </>
      );
    }

    return client.name;
  };

  const handleConfirm = (client: Client): void => {
    if (onConfirm) onConfirm(client);
    handleClose();
  };

  useEffect(() => {
    if (pagination.page > servicePagination?.last_page) {
      setPagination((oldPagination) => {
        if (oldPagination.page > 1) {
          return {
            ...oldPagination,
            page: 1,
          };
        }

        return oldPagination;
      });
    }
  }, [pagination.page, servicePagination, setPagination]);

  return (
    <Modal {...restProps} size="6xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Selecionar cliente</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <SimpleGrid columns={{ base: 1, md: 2 }} mt={6}>
            <HStack mb={3}>
              <Text fontSize="smaller">Por página:</Text>
              <Select
                width="auto"
                defaultValue={pagination.per_page}
                onChange={(e) => {
                  setPagination({
                    ...pagination,
                    per_page: Number(e.target.value),
                  });
                }}
              >
                {[5, 10, 20, 50, 100].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </HStack>

            <Flex
              flexWrap={{ base: 'wrap', md: 'nowrap' }}
              mb={3}
              gridGap={{ base: 0, md: 3 }}
            >
              <Input
                type="text"
                placeholder="Pesquisar"
                defaultValue={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                mb={{ base: 3, md: 0 }}
              />
            </Flex>
          </SimpleGrid>

          <AppTable
            cols={[
              { field: 'name', description: 'Nome', sortable: false },
              { field: 'phone', description: 'Telefones', sortable: false },
              { field: 'email', description: 'E-mail', sortable: false },
              { field: 'contact', description: 'Contato', sortable: false },
              { field: 'actions', description: 'Ações', sortable: false },
            ]}
            mapping={(item) => ({
              id: item.id,
              name: (
                <>
                  <Text mb={1}>{clientNameByType(item)}</Text>
                  <Text fontWeight="bold" fontSize="smaller">
                    {item.document}
                  </Text>
                </>
              ),
              phone: (
                <>
                  {item.phone}
                  <br />
                  {item.cell_phone}
                </>
              ),
              email: item.email,
              contact: item.contact,
              actions: (
                <Button
                  colorScheme="green"
                  leftIcon={<Icon as={CheckIcon} />}
                  onClick={() => handleConfirm(item)}
                >
                  Selecionar
                </Button>
              ),
            })}
            data={clientList}
            loading={loading}
            onUpdateSort={() => {
              //
            }}
          />

          <PaginationWrapper
            lastPage={servicePagination.last_page}
            onPaginate={(selectedPage) => {
              setPagination({ ...pagination, page: selectedPage });
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalClientLookup;
