import React, { useMemo, useState } from 'react';

import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  FormControl,
  Input,
  Grid,
  Text,
  Heading,
  Select,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

type FormErrors = {
  [key: string]: string | boolean;
  type: 'invalid' | 'required';
};

type Header = {
  [column: string]: string | number;
};

interface ModalImportProps extends ModalRootProps {
  data: {
    importId: number;
    mappedHeaders: Header;
    headerRow: number;
    baseId: number;
    baseName: string;
    versionName: string;
    fileName: string;
  };
}

const ModalImport: React.FC<ModalImportProps> = ({
  onConfirm,
  handleClose,
  data: { importId, mappedHeaders, headerRow, baseName, versionName, fileName },
  ...restProps
}) => {
  const [headers, setHeaders] = useState<Header>(mappedHeaders);
  const actualHeaders = useMemo(() => Object.keys(headers), [headers]);

  const [isUpdatingHeader, setIsUpdatingHeader] = useState(false);
  const [newHeaderLine, setNewHeaderLine] = useState(0);

  const [errors, setErrors] = useState<FormErrors>({} as FormErrors);
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    type_: '',
    code: '',
    composition_code: '',
    composition_type: '',
    class: '',
    description: '',
    unit_measure: '',
    unit_price: '',
    coefficient: '',
    untaxed_price: '',
    taxed_price: '',
  });

  const mappedColumns = useMemo(() => {
    return {
      type: fields.type_,
      code: fields.code,
      composition_code: fields.composition_code,
      composition_type: fields.composition_type,
      class: fields.class,
      description: fields.description,
      unit_measure: fields.unit_measure,
      unit_price: fields.unit_price,
      coefficient: fields.coefficient,
    };
  }, [fields]);

  const handleUpdateHeader = async (): Promise<void> => {
    try {
      setLoading(true);

      const {
        data: {
          data: { mapped_header },
        },
      } = await api.put(`/import/${importId}`, {
        header_row: newHeaderLine,
      });

      setHeaders(mapped_header);
    } catch (err) {
      toast({
        description:
          err.response?.data?.message ||
          'Houve um erro inesperado ao atualizar a linha do cabeçalho',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async (): Promise<void> => {
    const formErrors: FormErrors = {} as FormErrors;

    if (!fields.type_) {
      formErrors.type_ = 'Tipo é obrigatório';
    }

    if (!fields.code) {
      formErrors.code = 'Código é obrigatório';
    }

    if (!fields.composition_code) {
      formErrors.composition_code = 'Código da composição é obrigatório';
    }

    if (!fields.composition_type) {
      formErrors.composition_type = 'Tipo da composição é obrigatório';
    }

    if (!fields.class) {
      formErrors.class = 'Classe é obrigatório';
    }

    if (!fields.description) {
      formErrors.description = 'Descrição é obrigatório';
    }

    if (!fields.unit_measure) {
      formErrors.unit_measure = 'Unidade é obrigatório';
    }

    if (!fields.unit_price) {
      formErrors.unit_price = 'Preço é obrigatório';
    }

    if (!fields.coefficient) {
      formErrors.coefficient = 'Coeficiente é obrigatório';
    }

    setErrors(formErrors);
    if (Object.keys(formErrors).length) {
      return;
    }

    try {
      setLoading(true);

      const {
        data: {
          data: { id },
        },
      } = await api.put(`/import/${importId}`, {
        mapped_columns: mappedColumns,
        header_row: newHeaderLine || headerRow,
      });

      if (onConfirm) onConfirm(id);

      handleClose();
    } catch (err) {
      toast({
        description:
          err.response?.data?.message ||
          'Houve um erro inesperado ao importar o arquivo.',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal size="3xl" {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Configurar campos</ModalHeader>

        <ModalHeader
          mt="-6"
          fontSize="md"
          fontStyle="italic"
          display="flex"
          gap={2}
        >
          <Text>Base: {baseName}</Text>
          <Text>/</Text>
          <Text>Referência: {versionName}</Text>
          <Text>/</Text>
          <Text>Arquivo: {fileName}</Text>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Heading as="h3" fontWeight="medium" fontSize="lg" mb={8}>
            Indique a coluna do arquivo importado que corresponda a cada campo
            do orçamento
          </Heading>

          <Grid
            w="100%"
            templateColumns="repeat(auto-fit, minmax(250px, 1fr))"
            columnGap={2}
            rowGap={4}
          >
            <Text fontWeight="bold" mb={4}>
              Orçamento
            </Text>
            <Text fontWeight="bold" mb={4}>
              Arquivo importado (Colunas)
            </Text>
          </Grid>

          <Grid
            w="100%"
            templateColumns="repeat(auto-fit, minmax(250px, 1fr))"
            columnGap={2}
            rowGap={4}
          >
            <FormControl>
              <Input
                type="text"
                value="Tipo (Composição ou Insumo)"
                readOnly
                _readOnly={{ background: 'white' }}
              />
            </FormControl>

            <FormControl isInvalid={!!errors?.type_}>
              <Select
                onChange={(e) => {
                  setFields({ ...fields, type_: e.target.value });
                }}
              >
                <option value="">Selecione</option>
                {actualHeaders.map((column) => (
                  <option key={String(column)} value={column}>
                    {column}
                    {headerRow} - {headers[column]}
                  </option>
                ))}
              </Select>

              <FormErrorMessage>
                {!!errors?.type_ && 'Tipo é obrigatório'}
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <Input
                type="text"
                value="Código"
                readOnly
                _readOnly={{ background: 'white' }}
              />
            </FormControl>

            <FormControl isInvalid={!!errors?.code}>
              <Select
                onChange={(e) => {
                  setFields({ ...fields, code: e.target.value });
                }}
              >
                <option value="">Selecione</option>
                {actualHeaders.map((column) => (
                  <option key={String(column)} value={column}>
                    {column}
                    {headerRow} - {headers[column]}
                  </option>
                ))}
              </Select>

              <FormErrorMessage>
                {!!errors?.code && 'Código é obrigatório'}
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <Input
                type="text"
                value="Código da composição"
                readOnly
                _readOnly={{ background: 'white' }}
              />
            </FormControl>

            <FormControl isInvalid={!!errors?.composition_code}>
              <Select
                onChange={(e) => {
                  setFields({ ...fields, composition_code: e.target.value });
                }}
              >
                <option value="">Selecione</option>
                {actualHeaders.map((column) => (
                  <option key={String(column)} value={column}>
                    {column}
                    {headerRow} - {headers[column]}
                  </option>
                ))}
              </Select>

              <FormErrorMessage>
                {!!errors?.composition_code && 'Código é obrigatório'}
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <Input
                type="text"
                value="Tipo da composição"
                readOnly
                _readOnly={{ background: 'white' }}
              />
            </FormControl>

            <FormControl isInvalid={!!errors?.composition_type}>
              <Select
                onChange={(e) => {
                  setFields({ ...fields, composition_type: e.target.value });
                }}
              >
                <option value="">Selecione</option>
                {actualHeaders.map((column) => (
                  <option key={String(column)} value={column}>
                    {column}
                    {headerRow} - {headers[column]}
                  </option>
                ))}
              </Select>

              <FormErrorMessage>
                {!!errors?.composition_type && 'Tipo é obrigatório'}
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <Input
                type="text"
                value="Classe da composição ou tipo do insumo"
                readOnly
                _readOnly={{ background: 'white' }}
              />

              <br />
            </FormControl>

            <FormControl isInvalid={!!errors?.class}>
              <Select
                onChange={(e) => {
                  setFields({ ...fields, class: e.target.value });
                }}
              >
                <option value="">Selecione</option>
                {actualHeaders.map((column) => (
                  <option key={String(column)} value={column}>
                    {column}
                    {headerRow} - {headers[column]}
                  </option>
                ))}
              </Select>

              <FormErrorMessage>
                {!!errors?.class &&
                  'Classe da composição ou tipo do insumo é obrigatório'}
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <Input
                type="text"
                value="Descrição"
                readOnly
                _readOnly={{ background: 'white' }}
              />
            </FormControl>

            <FormControl isInvalid={!!errors?.description}>
              <Select
                onChange={(e) => {
                  setFields({ ...fields, description: e.target.value });
                }}
              >
                <option value="">Selecione</option>
                {actualHeaders.map((column) => (
                  <option key={String(column)} value={column}>
                    {column}
                    {headerRow} - {headers[column]}
                  </option>
                ))}
              </Select>

              <FormErrorMessage>
                {!!errors?.description && 'Código é obrigatório'}
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <Input
                type="text"
                value="Unidade"
                readOnly
                _readOnly={{ background: 'white' }}
              />
            </FormControl>

            <FormControl isInvalid={!!errors?.unit_measure}>
              <Select
                onChange={(e) => {
                  setFields({ ...fields, unit_measure: e.target.value });
                }}
              >
                <option value="">Selecione</option>
                {actualHeaders.map((column) => (
                  <option key={String(column)} value={column}>
                    {column}
                    {headerRow} - {headers[column]}
                  </option>
                ))}

                <FormErrorMessage>
                  {!!errors?.unit_measure && 'Código é obrigatório'}
                </FormErrorMessage>
              </Select>
            </FormControl>

            <FormControl>
              <Input
                type="text"
                value="Preço unitário"
                readOnly
                _readOnly={{ background: 'white' }}
              />
            </FormControl>

            <FormControl isInvalid={!!errors?.unit_price}>
              <Select
                onChange={(e) => {
                  setFields({ ...fields, unit_price: e.target.value });
                }}
              >
                <option value="">Selecione</option>
                {actualHeaders.map((column) => (
                  <option key={String(column)} value={column}>
                    {column}
                    {headerRow} - {headers[column]}
                  </option>
                ))}

                <FormErrorMessage>
                  {!!errors?.unit_price && 'Preço é obrigatório'}
                </FormErrorMessage>
              </Select>
            </FormControl>

            <FormControl>
              <Input
                type="text"
                value="Coeficiente"
                readOnly
                _readOnly={{ background: 'white' }}
              />
            </FormControl>

            <FormControl isInvalid={!!errors?.coefficient}>
              <Select
                onChange={(e) => {
                  setFields({ ...fields, coefficient: e.target.value });
                }}
              >
                <option value="">Selecione</option>
                {actualHeaders.map((column) => (
                  <option key={String(column)} value={column}>
                    {column}
                    {headerRow} - {headers[column]}
                  </option>
                ))}

                <FormErrorMessage>
                  {!!errors?.coefficient && 'Preço é obrigatório'}
                </FormErrorMessage>
              </Select>
            </FormControl>
          </Grid>

          <Text
            cursor="pointer"
            _hover={{
              textDecoration: 'underline',
            }}
            my={4}
            fontSize="sm"
            color="blue.500"
            onClick={() => {
              setIsUpdatingHeader(true);
            }}
          >
            Modificar linha do cabeçalho
          </Text>

          {isUpdatingHeader && (
            <>
              <FormControl mt={4}>
                <FormLabel>Selecione a nova linha do cabeçalho</FormLabel>
                <Input
                  type="number"
                  onChange={(e) => {
                    setNewHeaderLine(Number(e.target.value));
                  }}
                />
              </FormControl>

              <Button
                mt={4}
                colorScheme="blue"
                disabled={newHeaderLine === 0 || newHeaderLine < 0}
                isLoading={loading}
                onClick={async () => {
                  await handleUpdateHeader();
                  setIsUpdatingHeader(false);
                }}
              >
                Atualizar
              </Button>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={loading}
            isDisabled={loading}
            colorScheme="green"
            onClick={handleConfirm}
          >
            Próximo
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalImport;
