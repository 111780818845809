import React from 'react';
import ReactApexCharts from 'react-apexcharts';

import { StyledIcon } from '@styled-icons/styled-icon';
import { motion, AnimatePresence } from 'framer-motion';
import { MdiReactIconComponentType } from 'mdi-react';

import {
  Box,
  BoxProps,
  Icon,
  Heading,
  Text,
  Skeleton,
  Flex,
} from '@chakra-ui/react';

const MotionBox = motion(Box);

type SummaryData = {
  // tooltipLabel: string;
  number: number | React.Component;
  formattedNumber: number | string;
  altNumber?: number | string | React.Component;
  formattedAltNumber?: number | string;
  title: string | JSX.Element;
  icon?: StyledIcon | MdiReactIconComponentType | typeof Icon;
  colorSchemeType?: 'chakra' | 'manual';
  colorSchemeCard: string;

  chart: {
    colors: string[];
    labels: string[];
    series: number[];
  };
};
interface SummaryDataChartCardProps extends BoxProps {
  isHidden: boolean;
  idx: number;
  data: SummaryData;
  typeNumber: 'currency' | 'number';
  loading?: boolean;
}

const SummaryDataChartCard = (
  props: SummaryDataChartCardProps,
): React.ReactElement => {
  const {
    idx,
    isHidden,
    loading,
    typeNumber = 'currency',
    data: summaryData,
    ...rest
  } = props;

  const hasAnyData = Boolean(
    summaryData.chart.series.reduce((acc, curr) => acc + curr, 0),
  );

  return (
    <AnimatePresence>
      {!isHidden && (
        <MotionBox
          {...rest}
          padding="3"
          bg="white"
          borderWidth={1}
          borderRadius="8px"
          position="relative"
          width={{ base: '100%', lg: '460px' }}
          initial={{
            opacity: 0,
            scale: 0.4,
          }}
          animate={{
            opacity: 1,
            scale: 1,
            transition: {
              duration: 0.25 + (idx || 0) / 10,
            },
          }}
          exit={{
            opacity: 0,
            top: -30,
          }}
          transition="all 500ms ease"
        >
          <Flex
            direction={{ base: 'column', lg: 'row' }}
            alignItems="center"
            justifyContent="center"
            gap={50}
          >
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              padding={2}
            >
              {summaryData.icon && (
                <Flex
                  position="relative"
                  padding={2}
                  _before={{
                    content: '""',
                    background:
                      summaryData.colorSchemeType === 'manual'
                        ? summaryData.colorSchemeCard
                        : `${summaryData.colorSchemeCard}.200`,
                    position: 'absolute',
                    borderRadius: '50%',
                    padding: 6,
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    opacity: 0.2,
                  }}
                >
                  <Icon
                    as={summaryData.icon}
                    width={8}
                    height={8}
                    color={
                      summaryData.colorSchemeType === 'manual'
                        ? summaryData.colorSchemeCard
                        : `${summaryData.colorSchemeCard}.600`
                    }
                    overflow="hidden"
                  />
                </Flex>
              )}

              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                marginTop={2}
              >
                <Heading
                  fontSize="medium"
                  fontWeight="700"
                  width="auto"
                  textTransform="uppercase"
                  whiteSpace="nowrap"
                >
                  {loading ? (
                    <Skeleton width={100} height={4} />
                  ) : (
                    <>
                      {typeNumber === 'currency' ? 'R$ ' : ''}
                      {summaryData.formattedNumber}
                      {summaryData.altNumber && (
                        <Text
                          as="span"
                          fontSize="small"
                          fontWeight="400"
                          marginLeft={1}
                          color="gray"
                        >
                          ({summaryData.altNumber})
                        </Text>
                      )}
                    </>
                  )}
                </Heading>

                <Text marginTop={2} fontWeight="500" color="gray">
                  {summaryData.title}
                </Text>
              </Flex>
            </Flex>

            {hasAnyData && (
              <ReactApexCharts
                options={{
                  chart: {
                    type: 'pie',
                    fontFamily: 'system-ui',
                  },
                  labels: summaryData.chart.labels,
                  dataLabels: {
                    enabled: false,
                  },
                  colors: summaryData.chart.colors,
                  tooltip: {
                    y: {
                      formatter(value: number) {
                        return Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(value || 0);
                      },
                    },
                  },
                  legend: {
                    show: false,
                  },
                  responsive: [
                    {
                      breakpoint: 680,
                      options: {
                        chart: {
                          width: '100%',
                        },
                        legend: {
                          position: 'bottom',
                        },
                      },
                    },
                  ],
                }}
                series={summaryData.chart.series}
                type="pie"
                width="150px"
                height={180}
              />
            )}
          </Flex>
        </MotionBox>
      )}
    </AnimatePresence>
  );
};

export default SummaryDataChartCard;
