import React from 'react';
import ReactJson, { ThemeKeys } from 'react-json-view';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  HStack,
  Text,
  Select,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

import usePersistedState from 'hooks/usePersistedState';

type ModalJSONViewerProps = ModalRootProps & {
  data: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    json: any;
  };
};

const themes = [
  { key: 'apathy', description: 'Apathy' },
  { key: 'apathy:inverted', description: 'Apathy (Inverted)' },
  { key: 'ashes', description: 'Ashes' },
  { key: 'bespin', description: 'Bespin' },
  { key: 'brewer', description: 'Brewer' },
  { key: 'bright', description: 'Bright' },
  { key: 'bright:inverted', description: 'Bright (Inverted)' },
  { key: 'chalk', description: 'Chalk' },
  { key: 'codeschool', description: 'Codeschool' },
  { key: 'colors', description: 'Colors' },
  { key: 'eighties', description: 'Eighties' },
  { key: 'embers', description: 'Embers' },
  { key: 'flat', description: 'Flat' },
  { key: 'google', description: 'Google' },
  { key: 'grayscale', description: 'Grayscale' },
  { key: 'grayscale:inverted', description: 'Grayscale (Inverted)' },
  { key: 'greenscreen', description: 'Greenscreen' },
  { key: 'harmonic', description: 'Harmonic' },
  { key: 'hopscotch', description: 'Hopscotch' },
  { key: 'isotope', description: 'Isotope' },
  { key: 'marrakesh', description: 'Marrakesh' },
  { key: 'mocha', description: 'Mocha' },
  { key: 'monokai', description: 'Monokai' },
  { key: 'ocean', description: 'Ocean' },
  { key: 'paraiso', description: 'Paraiso' },
  { key: 'pop', description: 'Pop' },
  { key: 'railscasts', description: 'Railscasts' },
  { key: 'rjv-default', description: 'RJV Default' },
  { key: 'shapeshifter', description: 'Shapeshifter' },
  { key: 'shapeshifter:inverted', description: 'Shapeshifter (Inverted)' },
  { key: 'solarized', description: 'Solarized' },
  { key: 'summerfruit', description: 'Summerfruit' },
  { key: 'summerfruit:inverted', description: 'Summerfruit (Inverted)' },
  { key: 'threezerotwofour', description: 'Threezerotwofour' },
  { key: 'tomorrow', description: 'Tomorrow' },
  { key: 'tube', description: 'Tube' },
  { key: 'twilight', description: 'Twilight' },
];

const ModalJSONViewer: React.FC<ModalJSONViewerProps> = ({
  data = {},
  handleClose,
  ...restProps
}) => {
  const [theme, setTheme] = usePersistedState<ThemeKeys>(
    'ecustos:json-theme',
    'rjv-default',
  );

  return (
    <Modal {...restProps} size="4xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Visualizar resposta</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <HStack mb={3} display="flex" justifyContent="flex-end">
            <Text>Tema:</Text>
            <Select
              width="auto"
              defaultValue={theme}
              onChange={(e) => {
                setTheme(e.target.value as ThemeKeys);
              }}
            >
              {themes.map((item) => (
                <option key={item.key} value={item.key}>
                  {item.description}
                </option>
              ))}
            </Select>
          </HStack>

          <ReactJson
            name={null}
            src={data.json}
            displayObjectSize={false}
            displayDataTypes={false}
            theme={theme}
            enableClipboard={(copy) => {
              let src = '';

              if (
                typeof copy.src === 'string' ||
                typeof copy.src === 'number'
              ) {
                src = copy.src;
              } else {
                src = JSON.stringify(copy.src, null, 2);
              }

              navigator.clipboard.writeText(src);
            }}
            style={{
              borderRadius: '0.25rem',
              padding: '1rem',
              fontFamily: 'Fira Code, monospace',
            }}
          />
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button onClick={handleClose}>Fechar</Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalJSONViewer;
