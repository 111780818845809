import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useExpanded, useTable } from 'react-table';

import { DownArrow as DownArrowIcon } from '@styled-icons/boxicons-solid/DownArrow';
import { RightArrow as RightArrowIcon } from '@styled-icons/boxicons-solid/RightArrow';
import { TrafficCone as TrafficConeIcon } from '@styled-icons/entypo/TrafficCone';
import { StyledIcon } from '@styled-icons/styled-icon';
import { MdiReactIconComponentType } from 'mdi-react';
import BricksIcon from 'mdi-react/BricksIcon';
import GroupIcon from 'mdi-react/GroupIcon';
import InfoIcon from 'mdi-react/InfoCircleOutlineIcon';
import ShovelIcon from 'mdi-react/ShovelIcon';
import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  HStack,
  Icon,
  Box,
  SimpleGrid,
  Select,
} from '@chakra-ui/react';

import Loader from 'components/Loader';
import PaginationWrapper from 'components/Pagination';

import useThrottledState from 'hooks/useThrottledState';

import { ServicePagination, Pagination } from 'types/pagination';

import ImportStatusBadge from '../StatusBadge';
import ElementPreviewRow from './ElementPreviewTableRow';
import { ImportPreview, Row } from './types';

type Props = {
  importId: number;
  compositionCode: number;
};

type Status = 'all' | 'error' | 'warning' | 'success';

const ElementPreviewTable: React.FC<Props> = ({
  importId,
  compositionCode,
}) => {
  const [importPreview, setImportPreview] = useState<ImportPreview[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<Status>('all');

  const [pagination, setPagination] = useThrottledState<Pagination>(
    (() => {
      return {
        per_page: 20,
        page: 1,
      };
    })(),
    1000,
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const getImportPreview = useCallback(async () => {
    setLoading(true);
    try {
      const { data: responseData } = await api.get(
        `/import/${importId}/preview`,
        {
          params: {
            page: pagination.page,
            per_page: pagination.per_page,
            'filter[status]': status === 'all' ? undefined : status,
            'filter[composition_code]': compositionCode,
            'filter[_index]': 'import_element.*',
          },
        },
      );
      setImportPreview(responseData.data);
      setServicePagination({
        last_page: responseData.meta.last_page,
      });
    } catch (err) {
      toast({
        description: 'Houve um erro ao obter o preview do arquivo.',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [importId, pagination, compositionCode, status]);

  useEffect(() => {
    getImportPreview();
  }, [getImportPreview]);

  const shouldShowInputIcon = (i: ImportPreview): boolean => {
    return ['M', 'E', 'L'].includes(i._source.data?.class?.key || '');
  };

  type InputIcon = MdiReactIconComponentType | StyledIcon | undefined;
  const getInputIcon = useCallback((i: ImportPreview): InputIcon => {
    const value = i._source.data?.class?.key;

    if (!value) {
      return undefined;
    }

    if (value === 'M') {
      return BricksIcon;
    }

    if (value === 'E') {
      return TrafficConeIcon;
    }

    if (value === 'L') {
      return ShovelIcon;
    }

    return undefined;
  }, []);

  const getInputTitle = useCallback((i: ImportPreview): string => {
    const value = i._source.data?.class?.key;

    if (!value) {
      return '';
    }

    if (value === 'M') {
      return 'Material';
    }

    if (value === 'E') {
      return 'Equipamento';
    }

    if (value === 'L') {
      return 'Mão de obra';
    }

    return '';
  }, []);

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        Header: 'Tipo',
        width: '1%',
        Cell: ({ row }: { row: Row }) => {
          return (
            <HStack spacing={2}>
              {row.original._index.includes('composition') &&
                (row.isExpanded ? (
                  <Icon w={4} h={4} as={DownArrowIcon} />
                ) : (
                  <Icon w={4} h={4} as={RightArrowIcon} />
                ))}

              {row.original._index.includes('composition') && (
                <Box title="Composição">
                  <Icon w={4} h={4} as={GroupIcon} />
                </Box>
              )}

              {row.original._index.includes('input') && (
                <Box title="Insumo">
                  <Icon w={4} h={4} as={InfoIcon} />
                </Box>
              )}

              {shouldShowInputIcon(row.original) && (
                <Box title={getInputTitle(row.original)}>
                  <Icon w={4} h={4} as={getInputIcon(row.original)} />
                </Box>
              )}
            </HStack>
          );
        },
      },
      {
        Header: 'Linha',
        width: '1%',
        Cell: ({ row }: { row: Row }) => {
          return row.original._source.index;
        },
      },
      {
        Header: 'Descrição',
        width: '40%',
        Cell: ({ row }: { row: Row }) => {
          return (
            <Text
              maxWidth={{ base: '100%', md: '768px' }}
              whiteSpace="pre-wrap"
            >
              {row.original._source.raw_data.description || '-'}
            </Text>
          );
        },
      },
      {
        Header: 'Código',
        width: '5%',
        Cell: ({ row }: { row: Row }) => {
          return row.original._source.raw_data.code || '-';
        },
      },
      {
        Header: 'Unidade',
        width: '5%',
        Cell: ({ row }: { row: Row }) => {
          return row.original._source.raw_data.unit_measure;
        },
      },
      {
        Header: 'Valor unit',
        width: '5%',
        Cell: ({ row }: { row: Row }) => {
          const value = row.original._source.raw_data.unit_price;

          return value
            ? Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(value)
            : '-';
        },
      },
      {
        Header: 'Status',
        width: '1%',
        Cell: ({ row }: { row: Row }) => {
          return <ImportStatusBadge status={row.original._source.status} />;
        },
      },
      {
        Header: 'Erros',
        width: '10%',
        Cell: ({ row }: { row: Row }) => {
          return (
            row.original._source?.message && (
              <Text
                py={1}
                maxWidth={{ base: '100%', md: '150px', lg: '300px' }}
                whiteSpace="pre-wrap"
              >
                {Object.keys(row.original._source?.message).length > 0
                  ? Object.values(row.original._source?.message).join(', ')
                  : '-'}
              </Text>
            )
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getRowId = useCallback((row) => {
    return row._id;
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns,
      data: importPreview,
      getRowId,
    },
    useExpanded,
  );

  const renderRowSubComponent = useCallback(
    ({ compositionCode: nextCompositionCode }) => (
      <ElementPreviewTable
        compositionCode={nextCompositionCode}
        importId={importId}
      />
    ),
    [importId],
  );

  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 2 }}>
        <HStack mb={3}>
          <Text fontSize="smaller" color="white">
            Por página:
          </Text>
          <Select
            width="auto"
            defaultValue={pagination.per_page}
            onChange={(e) => {
              setPagination({
                ...pagination,
                per_page: Number(e.target.value),
              });
            }}
          >
            {[5, 10, 20, 50, 100].map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Select>
        </HStack>

        <HStack mb={3} display="flex" justifyContent="flex-end">
          <Text fontSize="smaller" color="white">
            Status:
          </Text>
          <Select
            width="auto"
            defaultValue={pagination.per_page}
            onChange={(e) => {
              setStatus(e.target.value as Status);
            }}
          >
            {[
              ['all', 'Todos'],
              ['success', 'Sucesso'],
              ['warning', 'Atenção'],
              ['error', 'Erro'],
            ].map(([key, description]) => (
              <option key={key} value={key}>
                {description}
              </option>
            ))}
          </Select>
        </HStack>
      </SimpleGrid>

      <TableContainer width="100%">
        <Table {...getTableProps()} borderBottom="none" variant="simple">
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps()}
                    width={column.width}
                    // @ts-expect-error isNumeric does not exists on lib type
                    isNumeric={column.isNumeric}
                  >
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {!loading &&
              rows.length > 0 &&
              rows.map((row) => {
                prepareRow(row);

                return (
                  <ElementPreviewRow
                    row={row as unknown as Row}
                    visibleColumns={visibleColumns}
                    renderRowSubComponent={renderRowSubComponent}
                    {...row.getRowProps()}
                  />
                );
              })}

            {loading && (
              <Tr>
                <Td
                  colSpan={visibleColumns.length}
                  borderBottom="none"
                  background="gray.100"
                >
                  <Loader />
                </Td>
              </Tr>
            )}

            {!loading && importPreview.length === 0 && (
              <Tr>
                <Td
                  colSpan={visibleColumns.length}
                  borderBottom="none"
                  background="gray.100"
                  className="text-center"
                >
                  Nenhum dado encontrado
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <PaginationWrapper
        useAlternativeColors
        lastPage={servicePagination.last_page}
        onPaginate={(selectedPage) => {
          setPagination({ ...pagination, page: selectedPage });
        }}
      />
    </>
  );
};

export default ElementPreviewTable;
