import React from 'react';

import { InfoCircleFill as InfoCircleIcon } from '@styled-icons/bootstrap/InfoCircleFill';
import { Warning as WarningIcon } from '@styled-icons/fluentui-system-filled/Warning';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
  Icon,
} from '@chakra-ui/react';

import { AlertRootProps } from 'components/Alert/Root';

const GenericAlert: React.FC<AlertRootProps> = ({
  type,
  title,
  description,
  buttons,
  onConfirm,
  handleClose,
  size = 'md',
  ...restProps
}) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const getAlertDescription = (
    descr?: string | (() => JSX.Element),
  ): string | JSX.Element => {
    if (descr) {
      return typeof descr === 'string' ? descr : descr();
    }

    return '';
  };

  return (
    <AlertDialog
      isOpen
      isCentered
      motionPreset="scale"
      leastDestructiveRef={cancelRef}
      onClose={handleClose}
      size={size}
      {...restProps}
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader display="flex" alignItems="center" gap={2}>
          {type === 'danger' ? (
            <Icon as={WarningIcon} w={6} h={6} color="red.500" />
          ) : (
            <Icon as={InfoCircleIcon} w={6} h={6} color="blue.300" />
          )}{' '}
          {title}
        </AlertDialogHeader>

        <AlertDialogCloseButton />

        <AlertDialogBody>{getAlertDescription(description)}</AlertDialogBody>

        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={handleClose}>
            {buttons?.[0] || 'Cancelar'}
          </Button>

          <Button
            ml={3}
            colorScheme={type === 'danger' ? 'red' : 'blue'}
            onClick={() => {
              if (onConfirm) onConfirm();
              handleClose();
            }}
          >
            {buttons?.[1] || 'Confirmar'}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default GenericAlert;
