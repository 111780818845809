import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';

import throttle from 'lodash/throttle';

type Response<T> = [T, Dispatch<SetStateAction<T>>];

function useThrottledState<T>(initialValue: T, delay = 300): Response<T> {
  const [thottledValue, setThrottledValue] = useState(initialValue);
  const [value, setValue] = useState(initialValue);

  const throttled = useRef(
    throttle((newValue: T) => setThrottledValue(newValue), delay),
  );

  useEffect(() => {
    if (throttled.current) {
      throttled.current(value);
    }
  }, [throttled, value]);

  return [thottledValue, setValue];
}

export default useThrottledState;
