import React from 'react';

import { Spinner, Image, Flex } from '@chakra-ui/react';

import logoSymbol from 'assets/img/symbol.svg';

const FallbackSpinner: React.FC = () => {
  return (
    <Flex
      width="100vw"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      direction="column"
      overflow="hidden"
    >
      <Image src={logoSymbol} width={50} marginBottom={4} />
      <Spinner width={5} height={5} />
    </Flex>
  );
};

export default FallbackSpinner;
