import React, { useRef, useState, useEffect } from 'react';
import Confetti from 'react-confetti';

import { Flex, useDimensions, useUpdateEffect } from '@chakra-ui/react';

const ConfettiEffect: React.FC = () => {
  const containerRef = useRef(null);
  const dimensions = useDimensions(containerRef, true);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [display, setDisplay] = useState(true);

  useUpdateEffect(() => {
    if (dimensions?.contentBox) {
      setWidth(dimensions.contentBox.width);

      setHeight(dimensions.contentBox.height);
    }
  }, [dimensions]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplay(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  if (!display) {
    return null;
  }

  return (
    <Flex
      ref={containerRef}
      position="fixed"
      width="100vw"
      height="100vh"
      top="0"
      bottom="0"
      right="0"
      left="0"
      overflow="hidden"
      zIndex="9999"
    >
      <Confetti
        width={width}
        height={height}
        numberOfPieces={400}
        recycle={false}
        tweenDuration={5000}
      />
    </Flex>
  );
};

export default ConfettiEffect;
