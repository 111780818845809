import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { CheckCircleFill as CheckIcon } from '@styled-icons/bootstrap/CheckCircleFill';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
  Icon,
  Text,
} from '@chakra-ui/react';

import { AlertRootProps } from 'components/Alert/Root';
import ConfettiEffect from 'components/ConfettiEffect';

function useQueryString(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

const BudgetExportSuccessAlert: React.FC<AlertRootProps> = ({
  buttons,
  handleClose,
  size = 'lg',
  ...restProps
}) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const queryString = useQueryString();

  const showConfetti = useMemo(() => {
    const easterEgg = queryString.get('easter-egg');
    if (!easterEgg) return false;

    return easterEgg === 'confete';
  }, [queryString]);

  return (
    <AlertDialog
      isOpen
      isCentered
      motionPreset="scale"
      leastDestructiveRef={cancelRef}
      onClose={handleClose}
      size={size}
      {...restProps}
    >
      <AlertDialogOverlay />
      {showConfetti && <ConfettiEffect />}

      <AlertDialogContent zIndex="99999">
        <AlertDialogHeader display="flex" alignItems="center" gap={2}>
          <Icon as={CheckIcon} w={4} h={4} color="green.500" /> Deu tudo certo!
        </AlertDialogHeader>

        <AlertDialogCloseButton />

        <AlertDialogBody>
          <Text fontSize="lg" fontWeight="bold" textAlign="center">
            Orçamento enviado com sucesso para o Sienge!
          </Text>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={handleClose}>
            {buttons?.[0] || 'Fechar'}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default BudgetExportSuccessAlert;
