import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
} from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Filter as FilterIcon } from '@styled-icons/fa-solid/Filter';
import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Button,
  Icon,
  Grid,
  FormControl,
  FormLabel,
  Flex,
  FormErrorMessage,
  Input,
} from '@chakra-ui/react';

import OwnSelect from 'components/OwnSelect';

import AuthContext from 'contexts/AuthContext';

import { Base, Version, Locale, UnitMeasure, PriceTypes } from 'types/base';

interface Qs {
  [key: string]: string | number | null | undefined;
}

export interface FilterHandles {
  open: () => void;
}

export interface FilterData extends Qs {
  base_id: number | null;
  version_id: number | null;
  locale_id: number | null;
  type_id: number | null;
  unit_measure_id: number | null;
  description: string;
}

interface FormProps {
  onSubmit(data: FilterData): void;
  loading?: boolean;
}

const OWN_BASE_KEY_PREFIX = 'own.';

const SearchFilter: React.FC<FormProps> = ({ onSubmit, loading = false }) => {
  const { user } = useContext(AuthContext);

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    register,
    clearErrors,
  } = useForm<FilterData>();

  const [bases, setBases] = useState<Base[]>([]);
  const [selectedBase, setSelectedBase] = useState<number | null>(null);
  const [isLoadingBases, setIsLoadingBases] = useState(false);

  const selectedBaseObj = useMemo(
    () => bases.find((b) => b.id === Number(selectedBase)),
    [bases, selectedBase],
  );

  const [baseVersions, setBaseVersions] = useState<Version[]>([]);
  const [selectedBaseVersion, setSelectedBaseVersion] = useState<number | null>(
    null,
  );
  const [isLoadingBaseVersions, setIsLoadingBaseVersions] = useState(false);

  const [baseLocales, setBaseLocales] = useState<Locale[]>([]);
  const [selectedBaseLocale, setSelectedBaseLocale] = useState<number | null>(
    null,
  );
  const [isLoadingBaseLocales, setIsLoadingBaseLocales] = useState(false);

  const [basePriceTypes, setBasePriceTypes] = useState<PriceTypes[]>([]);
  const [selectedBasePriceType, setSelectedBasePriceType] = useState<
    number | null
  >(null);
  const [isLoadingPriceTypes, setIsLoadingPriceTypes] = useState(false);

  const [unitMeasures, setUnitMeasures] = useState<UnitMeasure[]>([]);
  const [selectedUnitMeasure, setSelectedUnitMeasure] = useState<number | null>(
    null,
  );
  const [isLoadingUnitMeasures, setIsLoadingUnitMeasures] = useState(false);

  const prepareSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data,
      });
    },
    [onSubmit],
  );

  const getBases = useCallback(async () => {
    setIsLoadingBases(true);

    try {
      const { data: response } = await api.get('/base', {
        params: {
          'filter[with_plan]': `.`,
          'filter[organization_id]': user.managed_organization.organization.id,
          sort: 'description',
        },
      });
      const basesLoaded = response.data;
      setBases(basesLoaded);

      if (basesLoaded.length === 1) {
        const [base] = basesLoaded;
        setSelectedBase(base.id);
        setValue('base_id', base.id);

        if (errors.base_id) {
          clearErrors('base_id');
        }
      }
    } catch (err) {
      toast({
        description: 'Houve um erro ao carregar as bases!',
        status: 'error',
      });
    } finally {
      setIsLoadingBases(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const basesToSelect = useMemo(() => {
    return bases.map((base) => ({
      value: String(base.id),
      label: base.description,
    }));
  }, [bases]);

  useEffect(() => {
    getBases();
  }, [getBases]);

  const getBaseVersions = useCallback(
    async (baseId: number | null) => {
      if (!baseId) {
        setBaseVersions([]);
        return;
      }

      try {
        setIsLoadingBaseVersions(true);
        const { data: response } = await api.get('version', {
          params: {
            'filter[base_id]': baseId,
            sort: selectedBaseObj?.key.startsWith(OWN_BASE_KEY_PREFIX)
              ? `-created_at`
              : `-pointer`,
          },
        });
        const baseVersionsLoaded = response.data;
        setBaseVersions(baseVersionsLoaded);

        if (baseVersionsLoaded.length === 1) {
          const [baseVersion] = baseVersionsLoaded;
          setSelectedBaseVersion(baseVersion.id);
          setValue('version_id', baseVersion.id);

          if (errors.version_id) {
            clearErrors('version_id');
          }
        }
      } catch (err) {
        toast({
          description: 'Houve um erro ao carregar as versões da base!',
          status: 'error',
        });
      } finally {
        setIsLoadingBaseVersions(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedBaseObj],
  );

  const baseVersionsToSelect = useMemo(() => {
    return baseVersions.map((base) => ({
      value: String(base.id),
      label: base.description,
    }));
  }, [baseVersions]);

  useEffect(() => {
    getBaseVersions(selectedBase);
  }, [getBaseVersions, selectedBase]);

  const getBaseLocales = useCallback(async (baseId: number | null) => {
    if (!baseId) {
      setBaseLocales([]);
      return;
    }

    try {
      setIsLoadingBaseLocales(true);
      const { data: response } = await api.get('locale', {
        params: {
          'filter[base_id]': baseId,
          sort: 'description',
        },
      });
      const baseLocalesLoaded = response.data;
      setBaseLocales(baseLocalesLoaded);

      if (baseLocalesLoaded.length === 1) {
        const [baseLocale] = baseLocalesLoaded;
        setSelectedBaseLocale(baseLocale.id);
        setValue('locale_id', baseLocale.id);

        if (errors.locale_id) {
          clearErrors('locale_id');
        }
      }
    } catch (err) {
      toast({
        description: 'Houve um erro ao carregar os estados da base!',
        status: 'error',
      });
    } finally {
      setIsLoadingBaseLocales(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const baseLocalesToSelect = useMemo(() => {
    return baseLocales.map((locale) => ({
      value: String(locale.id),
      label: locale.description,
    }));
  }, [baseLocales]);

  useEffect(() => {
    getBaseLocales(selectedBase);
  }, [getBaseLocales, selectedBase]);

  const getBasePriceTypes = useCallback(async (baseId: number | null) => {
    if (!baseId) {
      setBasePriceTypes([]);
      return;
    }

    try {
      setIsLoadingPriceTypes(true);
      const { data: response } = await api.get('price/type', {
        params: {
          'filter[base_id]': baseId,
        },
      });
      const basePriceTypesLoaded = response.data;
      setBasePriceTypes(basePriceTypesLoaded);

      if (basePriceTypesLoaded.length === 1) {
        const [basePriceType] = basePriceTypesLoaded;
        setSelectedBasePriceType(basePriceType.id);
        setValue('type_id', basePriceType.id);

        if (errors.type_id) {
          clearErrors('type_id');
        }
      }
    } catch (err) {
      toast({
        description: 'Houve um erro ao carregar os tipos de preço da base!',
        status: 'error',
      });
    } finally {
      setIsLoadingPriceTypes(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const basePriceTypesToSelect = useMemo(() => {
    return basePriceTypes.map((type) => ({
      value: String(type.id),
      label: type.description,
    }));
  }, [basePriceTypes]);

  useEffect(() => {
    getBasePriceTypes(selectedBase);
  }, [getBasePriceTypes, selectedBase]);

  const getUnitMeasures = useCallback(async () => {
    setIsLoadingUnitMeasures(true);

    try {
      const { data: response } = await api.get('/unit/measure/list');
      const unitMeasuresLoaded = response.data;
      setUnitMeasures(unitMeasuresLoaded);
    } catch (err) {
      toast({
        description: 'Houve um erro ao carregar as unidades de medida!',
        status: 'error',
      });
    } finally {
      setIsLoadingUnitMeasures(false);
    }
  }, []);

  const unitMeasuresToSelect = useMemo(() => {
    return unitMeasures.map((unitMeasure) => ({
      value: String(unitMeasure.id),
      label: unitMeasure.description,
    }));
  }, [unitMeasures]);

  useEffect(() => {
    getUnitMeasures();
  }, [getUnitMeasures]);

  const clearBaseDependentValues = (): void => {
    setSelectedBaseVersion(null);
    setValue('version_id', null);

    setSelectedBaseLocale(null);
    setValue('locale_id', null);

    setSelectedBasePriceType(null);
    setValue('type_id', null);
  };

  type Selected = { value: string } | null;
  const handleBaseChange = (selected: Selected): void => {
    clearBaseDependentValues();

    const value = selected ? Number(selected.value) : null;

    if (errors.base_id) {
      clearErrors('base_id');
    }

    setSelectedBase(value);
    setValue('base_id', value);
  };

  const submitForm = (): void => {
    handleSubmit(prepareSubmit)();
  };

  return (
    <Flex
      w="100%"
      as="form"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{
        '> *': {
          marginY: 1,
        },
      }}
      onSubmit={handleSubmit(prepareSubmit)}
    >
      <Grid
        w="100%"
        templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        columnGap={2}
        rowGap={4}
      >
        <FormControl isInvalid={!!errors.base_id}>
          <FormLabel>Base de dados</FormLabel>

          <Controller
            render={(field) => (
              <OwnSelect
                {...field}
                placeholder="Selecione"
                isInvalid={!!errors.base_id}
                options={basesToSelect}
                value={basesToSelect.filter(
                  (s) => Number(s.value) === selectedBase,
                )}
                isLoading={isLoadingBases}
                isDisabled={isLoadingBases || basesToSelect.length === 0}
                isClearable
                onChange={handleBaseChange}
              />
            )}
            name="base_id"
            control={control}
            rules={{ required: true }}
          />

          <FormErrorMessage>
            {errors.base_id && 'Base é obrigatória.'}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.version_id}>
          <FormLabel>Referência</FormLabel>

          <Controller
            render={(field) => (
              <OwnSelect
                {...field}
                placeholder="Selecione"
                isInvalid={!!errors.version_id}
                options={baseVersionsToSelect}
                value={baseVersionsToSelect.filter(
                  (s) => Number(s.value) === selectedBaseVersion,
                )}
                isLoading={isLoadingBaseVersions}
                isDisabled={
                  isLoadingBaseVersions || baseVersionsToSelect.length === 0
                }
                isClearable
                onChange={(selected) => {
                  const value = selected ? Number(selected.value) : null;

                  setSelectedBaseVersion(value);
                  setValue('version_id', value);

                  if (value && errors.version_id) {
                    clearErrors('version_id');
                  }
                }}
              />
            )}
            name="version_id"
            control={control}
            rules={{ required: true }}
          />

          <FormErrorMessage>
            {errors.version_id && 'Referência é obrigatória.'}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.locale_id}>
          <FormLabel>Estado</FormLabel>

          <Controller
            render={(field) => (
              <OwnSelect
                {...field}
                placeholder="Selecione"
                isInvalid={!!errors.locale_id}
                options={baseLocalesToSelect}
                value={baseLocalesToSelect.filter(
                  (s) => Number(s.value) === selectedBaseLocale,
                )}
                isLoading={isLoadingBaseLocales}
                isDisabled={
                  isLoadingBaseLocales || baseLocalesToSelect.length === 0
                }
                isClearable
                onChange={(selected) => {
                  const value = selected ? Number(selected.value) : null;

                  setSelectedBaseLocale(value);
                  setValue('locale_id', value);

                  if (value && errors.locale_id) {
                    clearErrors('locale_id');
                  }
                }}
              />
            )}
            name="locale_id"
            control={control}
            rules={{ required: true }}
          />

          <FormErrorMessage>
            {errors.locale_id && 'Estado é obrigatório.'}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.type_id}>
          <FormLabel>Tipo</FormLabel>

          <Controller
            render={(field) => (
              <OwnSelect
                {...field}
                placeholder="Selecione"
                isInvalid={!!errors.type_id}
                options={basePriceTypesToSelect}
                value={basePriceTypesToSelect.filter(
                  (s) => Number(s.value) === selectedBasePriceType,
                )}
                isLoading={isLoadingPriceTypes}
                isDisabled={
                  isLoadingPriceTypes || basePriceTypesToSelect.length === 0
                }
                isClearable
                onChange={(selected) => {
                  const value = selected ? Number(selected.value) : null;

                  setSelectedBasePriceType(value);
                  setValue('type_id', value);

                  if (value && errors.type_id) {
                    clearErrors('type_id');
                  }
                }}
              />
            )}
            name="type_id"
            control={control}
            rules={{ required: true }}
          />

          <FormErrorMessage>
            {errors.type_id && 'Tipo é obrigatório.'}
          </FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>Unidade de medida</FormLabel>

          <Controller
            render={(field) => (
              <OwnSelect
                {...field}
                placeholder="Selecione"
                maxMenuHeight={200}
                options={unitMeasuresToSelect}
                value={unitMeasuresToSelect.filter(
                  (s) => Number(s.value) === selectedUnitMeasure,
                )}
                isLoading={isLoadingUnitMeasures}
                isDisabled={
                  isLoadingUnitMeasures || unitMeasuresToSelect.length === 0
                }
                isClearable
                onChange={(selected) => {
                  const value = selected ? Number(selected.value) : null;

                  setSelectedUnitMeasure(value);
                  setValue('unit_measure_id', value);
                }}
              />
            )}
            name="unit_measure_id"
            control={control}
          />
        </FormControl>
      </Grid>

      <Grid
        w="100%"
        templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        columnGap={2}
        rowGap={4}
        marginTop="4"
      >
        <FormControl>
          <FormLabel>Pesquisar por descrição / código da insumo</FormLabel>
          <Input {...register('description')} />
        </FormControl>
      </Grid>

      <Button
        w="100%"
        type="submit"
        colorScheme="blue"
        isLoading={loading}
        leftIcon={<Icon as={FilterIcon} />}
        onClick={submitForm}
        mt="4"
        mb="4"
      >
        Filtrar
      </Button>
    </Flex>
  );
};

export default SearchFilter;
