import React, { useEffect, useState } from 'react';

import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

type FormErrors = {
  [key: string]: string | boolean;
  type: 'invalid' | 'required';
};

interface ModalVersionAddProps extends ModalRootProps {
  data: {
    base_id?: number;
    version_id?: number;
    description?: string;
    reference?: string;
  };
}

const ModalVersionAdd: React.FC<ModalVersionAddProps> = ({
  onConfirm,
  handleClose,
  data = {},
  mode,
  ...restProps
}) => {
  const [description, setDescription] = useState<string>(
    data.description || '',
  );
  const [referenceMonth] = useState<string>(data.reference || '');

  const descriptionRef = React.useRef<HTMLInputElement>(null);
  // const referenceMonthRef = React.useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState<FormErrors | undefined>();

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.focus();
    }
  }, []);

  const handleConfirm = async (): Promise<void> => {
    const formErrors: FormErrors = {} as FormErrors;

    if (!description) {
      Object.assign(formErrors, { description: true, type: 'required' });
    }

    setErrors(formErrors);
    if (Object.keys(formErrors).length) {
      return;
    }

    const [year, month] = referenceMonth.split('-');
    const yearNum = Number(year);
    const monthNum = Number(month);

    try {
      setLoading(true);
      if (mode === 'add') {
        await api.post(`/version`, {
          base_id: data.base_id,
          description,
          ...(!!referenceMonth && { month: monthNum, year: yearNum }),
        });
      } else if (mode === 'edit') {
        await api.put(`/version/${data.version_id}`, {
          base_id: data.base_id,
          description,
          ...(!!referenceMonth && { month: monthNum, year: yearNum }),
        });
      }
      if (onConfirm) onConfirm();
      toast({
        description:
          mode === 'add'
            ? 'Referência adicionada com sucesso!'
            : 'Referência editada com sucesso!',
        status: 'success',
      });
      handleClose();
    } catch (err) {
      toast({
        description:
          mode === 'add'
            ? 'Houve um erro ao adicionar a referência!'
            : 'Houve um erro ao editar a referência!',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {mode === 'add' ? 'Adicionar referência' : 'Editar referência'}
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <FormControl marginTop="4" isInvalid={!!errors?.description}>
            <FormLabel>Descrição</FormLabel>

            <Input
              ref={descriptionRef}
              w="100%"
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleConfirm()}
            />

            <FormErrorMessage>
              {!!errors?.description && 'Descrição é obrigatória'}
            </FormErrorMessage>
          </FormControl>
          {/*
          <FormControl marginTop="4">
            <FormLabel>Mês/Ano</FormLabel>

            <Input
              ref={referenceMonthRef}
              w="100%"
              type="month"
              value={referenceMonth}
              onChange={(e) => setReferenceMonth(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleConfirm()}
            />

            <FormErrorMessage>
              {!!errors?.reference && 'Referência é obrigatória'}
            </FormErrorMessage>
          </FormControl> */}
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={loading}
            isDisabled={loading}
            colorScheme="green"
            onClick={handleConfirm}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalVersionAdd;
