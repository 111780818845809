import React, { useCallback, useMemo, useEffect } from 'react';
import { useExpanded, useTable } from 'react-table';

import { DownArrow as DownArrowIcon } from '@styled-icons/boxicons-solid/DownArrow';
import { RightArrow as RightArrowIcon } from '@styled-icons/boxicons-solid/RightArrow';
import GroupIcon from 'mdi-react/GroupIcon';

import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  HStack,
  Icon,
  Box,
} from '@chakra-ui/react';

import Loader from 'components/Loader';

import ImportStatusBadge from '../StatusBadge';
import ElementPreviewTable from './ElementPreviewTable';
import ImportPreviewRow from './ImportPreviewTableRow';
import { ImportPreview, Row } from './types';

type Props = {
  data: ImportPreview[];
  importId: number;
  loading?: boolean;
};

const ImportPreviewTable: React.FC<Props> = ({ data, loading, importId }) => {
  const [importPreview, setImportPreview] = React.useState(data);

  useEffect(() => {
    setImportPreview(data);
  }, [data]);

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        Header: 'Tipo',
        width: '1%',
        Cell: ({ row }: { row: Row }) => {
          return (
            <HStack>
              {row.original._index.includes('composition') &&
                (row.isExpanded ? (
                  <Icon w={4} h={4} as={DownArrowIcon} />
                ) : (
                  <Icon w={4} h={4} as={RightArrowIcon} />
                ))}

              <Box title="Composição">
                <Icon w={4} h={4} as={GroupIcon} />
              </Box>
            </HStack>
          );
        },
      },
      {
        Header: 'Linha',
        width: '1%',
        Cell: ({ row }: { row: Row }) => {
          return row.original._source.index;
        },
      },
      {
        Header: 'Descrição',
        width: '40%',
        Cell: ({ row }: { row: Row }) => {
          return (
            <Text
              maxWidth={{ base: '100%', md: '768px' }}
              whiteSpace="pre-wrap"
            >
              {row.original._source.raw_data.description || '-'}
            </Text>
          );
        },
      },
      {
        Header: 'Código',
        width: '5%',
        Cell: ({ row }: { row: Row }) => {
          return row.original._source.raw_data.code || '-';
        },
      },
      {
        Header: 'Unidade',
        width: '5%',
        Cell: ({ row }: { row: Row }) => {
          return row.original._source.raw_data.unit_measure;
        },
      },
      {
        Header: 'Valor unit',
        width: '5%',
        Cell: ({ row }: { row: Row }) => {
          const value = row.original._source.raw_data.unit_price;

          return value
            ? Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(value)
            : '-';
        },
      },
      {
        Header: 'Status',
        width: '1%',
        Cell: ({ row }: { row: Row }) => {
          return <ImportStatusBadge status={row.original._source.status} />;
        },
      },
      {
        Header: 'Erros',
        width: '10%',
        Cell: ({ row }: { row: Row }) => {
          return (
            row.original._source?.message && (
              <Text
                py={1}
                maxWidth={{ base: '100%', md: '150px', lg: '300px' }}
                whiteSpace="pre-wrap"
              >
                {Object.keys(row.original._source?.message).length > 0
                  ? Object.values(row.original._source?.message).join(', ')
                  : '-'}
              </Text>
            )
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getRowId = useCallback((row) => {
    return row._id;
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns,
      data: importPreview,
      getRowId,
    },
    useExpanded,
  );

  const renderRowSubComponent = useCallback(
    ({ compositionCode }) => (
      <ElementPreviewTable
        compositionCode={compositionCode}
        importId={importId}
      />
    ),
    [importId],
  );

  return (
    <TableContainer width="100%">
      <Table {...getTableProps()} borderBottom="none" variant="simple">
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps()}
                  width={column.width}
                  // @ts-expect-error isNumeric does not exists on lib type
                  isNumeric={column.isNumeric}
                >
                  {column.render('Header')}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        <Tbody {...getTableBodyProps()}>
          {!loading &&
            rows.length > 0 &&
            rows.map((row) => {
              prepareRow(row);

              return (
                <ImportPreviewRow
                  row={row as unknown as Row}
                  visibleColumns={visibleColumns}
                  renderRowSubComponent={renderRowSubComponent}
                  {...row.getRowProps()}
                />
              );
            })}

          {loading && (
            <Tr>
              <Td
                colSpan={visibleColumns.length}
                borderBottom="none"
                background="gray.100"
              >
                <Loader />
              </Td>
            </Tr>
          )}

          {!loading && data.length === 0 && (
            <Tr>
              <Td
                colSpan={visibleColumns.length}
                borderBottom="none"
                background="gray.100"
                className="text-center"
              >
                Suas importações aparecerão aqui.
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ImportPreviewTable;
