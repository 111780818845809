import React from 'react';

import {
  Flex,
  Grid,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
} from '@chakra-ui/react';

import { PreparedClient } from 'types/client';

interface FormProps {
  initialData?: PreparedClient;
}

const FormClientLookup: React.FC<FormProps> = ({ initialData }) => {
  return (
    <Flex
      w="100%"
      as="form"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{
        '> *': {
          marginY: 1,
        },
      }}
    >
      <Grid
        templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        columnGap={2}
        rowGap={2}
        marginTop={4}
        width="100%"
      >
        <FormControl>
          <FormLabel>Tipo</FormLabel>

          <Select defaultValue={initialData?.type_person} isReadOnly>
            <option value="physical">Pessoa física</option>
            <option value="legal">Pessoa jurídica</option>
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel>
            {initialData?.type_person === 'legal' ? 'CNPJ' : 'CPF'}
          </FormLabel>

          <Input
            type="text"
            placeholder="Documento"
            defaultValue={initialData?.document}
            isReadOnly
          />
        </FormControl>
      </Grid>

      {initialData?.type_person === 'physical' && (
        <FormControl>
          <FormLabel>Nome completo</FormLabel>

          <Input
            type="text"
            placeholder="Nome"
            defaultValue={initialData.name}
            isReadOnly
          />
        </FormControl>
      )}

      {initialData?.type_person === 'legal' && (
        <Grid
          templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
          columnGap={2}
          rowGap={2}
          marginTop={4}
          width="100%"
        >
          <FormControl>
            <FormLabel>Razão Social</FormLabel>

            <Input
              type="text"
              placeholder="Razão social"
              defaultValue={initialData.corporate_name}
              isReadOnly
            />
          </FormControl>

          <FormControl>
            <FormLabel>Nome fantasia</FormLabel>

            <Input
              type="text"
              placeholder="Nome fantasia"
              defaultValue={initialData.fantasy_name}
              isReadOnly
            />
          </FormControl>
        </Grid>
      )}

      <Grid
        templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        columnGap={2}
        rowGap={2}
        marginTop={4}
        width="100%"
      >
        <FormControl>
          <FormLabel>Telefone (fixo)</FormLabel>
          <Input
            type="text"
            placeholder="Fone"
            defaultValue={initialData?.phone}
            isReadOnly
          />
        </FormControl>

        <FormControl>
          <FormLabel>Telefone (celular / WhatsApp)</FormLabel>

          <Input
            type="text"
            placeholder="Celular"
            defaultValue={initialData?.cell_phone}
            isReadOnly
          />
        </FormControl>
      </Grid>

      <Grid
        templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        columnGap={2}
        rowGap={2}
        marginTop={4}
        width="100%"
      >
        <FormControl>
          <FormLabel>Email</FormLabel>

          <Input
            type="email"
            placeholder="exemplo@exemplo.com"
            defaultValue={initialData?.email}
            isReadOnly
          />
        </FormControl>

        <FormControl>
          <FormLabel>Contato</FormLabel>

          <Input type="text" defaultValue={initialData?.contact} isReadOnly />
        </FormControl>
      </Grid>

      <Grid
        templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        columnGap={2}
        rowGap={2}
        marginTop={4}
        width="100%"
      >
        <FormControl>
          <FormLabel htmlFor="cep">CEP</FormLabel>
          <Input
            type="text"
            placeholder="CEP"
            defaultValue={initialData?.cep}
            isReadOnly
          />
        </FormControl>

        <FormControl>
          <FormLabel>Estado</FormLabel>

          <Input
            type="text"
            defaultValue={initialData?.state_name}
            isReadOnly
          />
        </FormControl>

        <FormControl>
          <FormLabel>Cidade</FormLabel>

          <Input type="text" defaultValue={initialData?.city_name} isReadOnly />
        </FormControl>
      </Grid>

      <Grid
        templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        columnGap={2}
        rowGap={2}
        marginTop={4}
        width="100%"
      >
        <FormControl>
          <FormLabel>Endereço</FormLabel>

          <Input
            type="text"
            placeholder="Ex: Rua Padre João"
            defaultValue={initialData?.address}
            isReadOnly
          />
        </FormControl>

        <FormControl>
          <FormLabel>Número</FormLabel>

          <Input
            type="number"
            placeholder="Ex: 123"
            defaultValue={initialData?.number}
            isReadOnly
          />
        </FormControl>

        <FormControl>
          <FormLabel>Complemento</FormLabel>

          <Input
            type="text"
            placeholder="Ex: Apartamento 10"
            defaultValue={initialData?.complement}
            isReadOnly
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="name">Bairro</FormLabel>
          <Input
            type="text"
            placeholder="Ex: Vila Mariana"
            defaultValue={initialData?.district}
            isReadOnly
          />
        </FormControl>
      </Grid>

      <FormControl marginTop="4">
        <FormLabel>Observação</FormLabel>
        <Textarea
          resize="none"
          defaultValue={initialData?.observation}
          background="#E9ECEF"
          isReadOnly
        />
      </FormControl>
    </Flex>
  );
};

export default FormClientLookup;
