import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Check as CheckIcon } from '@styled-icons/boxicons-regular/Check';
import { Search as SearchIcon } from '@styled-icons/boxicons-regular/Search';
import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  HStack,
  Flex,
  Input,
  Select,
  Button,
  Icon,
  Text,
  FormControl,
  FormLabel,
  InputRightAddon,
  IconButton,
  InputGroup,
} from '@chakra-ui/react';

import AppTable from 'components/AppTable';
import { ModalRootProps } from 'components/Modal/Root';
import PaginationWrapper from 'components/Pagination';

import useThrottledState from 'hooks/useThrottledState';

import { Enterprise } from 'types/enterprise';
import { Pagination, ServicePagination } from 'types/pagination';

const ModalEnterpriseLookup: React.FC<ModalRootProps> = ({
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const [data, setData] = useState<Enterprise[]>([]);
  const [loading, setLoading] = useState(false);

  const [id, setId] = useState('');
  const idInputRef = useRef<HTMLInputElement>(null);

  const [initialLoad, setInitialLoad] = useState(false);

  const [pagination, setPagination] = useThrottledState<Pagination>(
    (() => {
      return {
        per_page: 10,
        page: 1,
      };
    })(),
    1000,
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const getEnterpriseList = useCallback(async () => {
    setLoading(true);
    setData([]);

    let enterprises: Enterprise[] = [];

    try {
      if (id) {
        const response = await api.get(`/enterprise/${id}`);
        const found = response.data;
        enterprises = [found.data];
      } else {
        const response = await api.get('/enterprise', {
          params: {
            page: pagination.page,
            per_page: pagination.per_page,
          },
        });

        const enterprisesData = response.data;
        enterprises = enterprisesData.data;

        const newPagination = {
          last_page: enterprisesData.meta.last_page,
        };

        setServicePagination(newPagination);
      }
      setData(enterprises);
    } catch (err) {
      toast({
        description:
          err.response?.data?.message || 'Ocorreu um erro ao buscar as obras.',
        status: 'error',
      });

      setData([]);
      setServicePagination({ last_page: 1 });
    } finally {
      setLoading(false);
    }
  }, [pagination, id]);

  useEffect(() => {
    getEnterpriseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const handleConfirm = (enterprise: Enterprise): void => {
    if (onConfirm) onConfirm(enterprise);
    handleClose();
  };

  useEffect(() => {
    if (pagination.page > servicePagination?.last_page) {
      setPagination((oldPagination) => {
        if (oldPagination.page > 1) {
          return {
            ...oldPagination,
            page: 1,
          };
        }

        return oldPagination;
      });
    }
  }, [pagination.page, servicePagination, setPagination]);

  useEffect(() => {
    if (!initialLoad) {
      setInitialLoad(true);
    }
  }, [initialLoad]);

  useEffect(() => {
    if (initialLoad && idInputRef.current) {
      idInputRef.current.focus();
    }
  }, [initialLoad]);

  return (
    <Modal {...restProps} size="6xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Selecionar obra</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <SimpleGrid columns={{ base: 1, md: 2 }} mt={6}>
            <HStack mb={3}>
              <Text fontSize="smaller">Por página:</Text>
              <Select
                width="auto"
                defaultValue={pagination.per_page}
                onChange={(e) => {
                  setPagination({
                    ...pagination,
                    per_page: Number(e.target.value),
                  });
                }}
              >
                {[5, 10, 20, 50, 100].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </HStack>

            <Flex
              flexWrap={{ base: 'wrap', md: 'nowrap' }}
              mb={3}
              gridGap={{ base: 0, md: 3 }}
            >
              <FormControl>
                <FormLabel>Pesquisar por código</FormLabel>
                <InputGroup>
                  <Input
                    type="text"
                    ref={idInputRef}
                    placeholder="Pesquisar"
                    defaultValue={id}
                    onChange={(e) => setId(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        getEnterpriseList();
                      }
                    }}
                    mb={{ base: 3, md: 0 }}
                  />
                  <InputRightAddon px={0}>
                    <IconButton
                      height="100%"
                      colorScheme="blue"
                      aria-label="Pesquisar obra"
                      title="Pesquisar obra"
                      borderTopLeftRadius="0"
                      borderBottomLeftRadius="0"
                      onClick={getEnterpriseList}
                      isLoading={loading}
                      width="50px"
                    >
                      <Icon as={SearchIcon} />
                    </IconButton>
                  </InputRightAddon>
                </InputGroup>
              </FormControl>
            </Flex>
          </SimpleGrid>

          <AppTable
            cols={[
              { field: 'name', description: 'Obra', sortable: false },
              {
                field: 'actions',
                description: 'Ações',
                sortable: false,
                isCentered: true,
              },
            ]}
            mapping={(item) => ({
              id: item.external_id,
              name: (
                <Text
                  minWidth={{ base: '100%', md: '350px' }}
                  maxWidth={{ base: '100%', md: '768px' }}
                  whiteSpace="pre-wrap"
                >
                  <Text as="span" fontWeight="bold">
                    {item.external_id}
                  </Text>
                  . {item.name}
                </Text>
              ),
              actions: (
                <Button
                  colorScheme="green"
                  leftIcon={<Icon as={CheckIcon} />}
                  onClick={() => handleConfirm(item)}
                >
                  Selecionar
                </Button>
              ),
            })}
            data={data}
            loading={loading}
            onUpdateSort={() => {
              //
            }}
          />

          <PaginationWrapper
            lastPage={data.length === 1 ? 1 : servicePagination?.last_page}
            onPaginate={(selectedPage) => {
              setPagination({ ...pagination, page: selectedPage });
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalEnterpriseLookup;
