import React, { useState, useEffect, useCallback } from 'react';

import { Check as CheckIcon } from '@styled-icons/boxicons-regular/Check';
import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Icon,
  Text,
  Box,
} from '@chakra-ui/react';

import AppTable from 'components/AppTable';
import { ModalRootProps } from 'components/Modal/Root';

type SiengeOrganization = {
  docNumber: string;
  id: string;
  name: string;
  provider: string;
};

interface ModalSiengeOrganizationLookupProps extends ModalRootProps {
  data: {
    state: string;
    code: string;
  };
}

const ModalSiengeOrganizationLookup: React.FC<ModalSiengeOrganizationLookupProps> =
  ({ data: modalData, onConfirm, handleClose, ...restProps }) => {
    const [data, setData] = useState<SiengeOrganization[]>([]);
    const [token, setToken] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const [initialLoad, setInitialLoad] = useState(false);

    const getOrganizations = useCallback(async () => {
      setLoading(true);
      setData([]);

      let organizations: SiengeOrganization[] = [];

      try {
        const response = await api.get('/organization/external/siengeId', {
          params: {
            code: modalData.code,
            state: modalData.state,
          },
        });

        const organizationsData = response.data;
        organizations = organizationsData['0'];

        const userInfo = organizationsData.user_info;

        setData(organizations);
        setToken(userInfo?.token);
      } catch (err) {
        toast({
          description: 'Não foi possível carregar as organizações.',
          status: 'error',
        });
      } finally {
        setLoading(false);
      }
    }, [modalData]);

    useEffect(() => {
      if (initialLoad) {
        getOrganizations();
      }
    }, [initialLoad, getOrganizations]);

    const handleConfirm = (org: SiengeOrganization): void => {
      if (onConfirm) onConfirm(org, token);
      handleClose();
    };

    useEffect(() => {
      if (!initialLoad) {
        setInitialLoad(true);
      }
    }, [initialLoad]);

    return (
      <Modal {...restProps} size="6xl" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Selecionar organização Sienge ID</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <AppTable
              cols={[
                { field: 'name', description: 'Organização', sortable: false },
                {
                  field: 'actions',
                  description: 'Ações',
                  sortable: false,
                  isCentered: true,
                },
              ]}
              mapping={(item) => ({
                id: item.id,
                name: (
                  <Text
                    minWidth={{ base: '100%', md: '350px' }}
                    maxWidth={{ base: '100%', md: '768px' }}
                    whiteSpace="pre-wrap"
                  >
                    {item.name}
                  </Text>
                ),
                actions: (
                  <Button
                    colorScheme="green"
                    leftIcon={<Icon as={CheckIcon} />}
                    onClick={() => handleConfirm(item)}
                  >
                    Selecionar
                  </Button>
                ),
              })}
              data={data}
              loading={loading}
              onUpdateSort={() => {
                //
              }}
            />

            <Box my={4} />
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

export default ModalSiengeOrganizationLookup;
