import React, { useState, useEffect, useCallback } from 'react';

import { Check as CheckIcon } from '@styled-icons/boxicons-regular/Check';
import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  HStack,
  Select,
  Button,
  Icon,
  Text,
} from '@chakra-ui/react';

import AppTable from 'components/AppTable';
import { ModalRootProps } from 'components/Modal/Root';
import PaginationWrapper from 'components/Pagination';

import useThrottledState from 'hooks/useThrottledState';

import { Pagination, ServicePagination } from 'types/pagination';
import { UnitConstructive } from 'types/unit-constructive';

interface ModalUnitConstructiveLookupProps extends ModalRootProps {
  data: {
    enterprise_id: string;
  };
}

const ModalUnitConstructiveLookup: React.FC<ModalUnitConstructiveLookupProps> =
  ({ onConfirm, handleClose, data, ...restProps }) => {
    const [unitConstructiveList, setUnitConstructiveList] = useState<
      UnitConstructive[]
    >([]);

    const [loading, setLoading] = useState(false);

    const [pagination, setPagination] = useThrottledState<Pagination>(
      (() => {
        return {
          per_page: 10,
          page: 1,
        };
      })(),
      1000,
    );

    const [servicePagination, setServicePagination] =
      useState<ServicePagination>({ last_page: 1 });

    const getUnitConstructiveList = useCallback(async () => {
      if (!data.enterprise_id) {
        return;
      }

      setLoading(true);
      setUnitConstructiveList([]);

      try {
        const response = await api.get('/unit/constructive', {
          params: {
            page: pagination.page,
            per_page: pagination.per_page,
            'params[building_id]': data.enterprise_id,
          },
        });

        const units = response.data;

        const newPagination = {
          last_page: units.meta.last_page,
        };

        setUnitConstructiveList(units.data);
        setServicePagination(newPagination);
      } catch (err) {
        toast({
          description:
            err.response?.data?.message ||
            'Ocorreu um erro ao buscar as unidades construtivas.',
          status: 'error',
        });

        setUnitConstructiveList([]);
        setServicePagination({ last_page: 1 });
      } finally {
        setLoading(false);
      }
    }, [pagination, data.enterprise_id]);

    useEffect(() => {
      getUnitConstructiveList();
    }, [getUnitConstructiveList]);

    const handleConfirm = (unit: UnitConstructive): void => {
      if (onConfirm) onConfirm(unit);
      handleClose();
    };

    useEffect(() => {
      if (pagination.page > servicePagination?.last_page) {
        setPagination((oldPagination) => {
          if (oldPagination.page > 1) {
            return {
              ...oldPagination,
              page: 1,
            };
          }

          return oldPagination;
        });
      }
    }, [pagination.page, servicePagination, setPagination]);

    return (
      <Modal {...restProps} size="6xl" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Selecionar unidade construtiva</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <SimpleGrid columns={{ base: 1, md: 2 }} mt={6}>
              <HStack mb={3}>
                <Text fontSize="smaller">Por página:</Text>
                <Select
                  width="auto"
                  defaultValue={pagination.per_page}
                  onChange={(e) => {
                    setPagination({
                      ...pagination,
                      per_page: Number(e.target.value),
                    });
                  }}
                >
                  {[5, 10, 20, 50, 100].map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </HStack>
            </SimpleGrid>

            <AppTable
              cols={[
                {
                  field: 'name',
                  description: 'Unidade construtiva',
                  sortable: false,
                },
                {
                  field: 'actions',
                  description: 'Ações',
                  sortable: false,
                  isCentered: true,
                },
              ]}
              mapping={(item) => ({
                id: item.external_id,
                name: (
                  <Text
                    minWidth={{ base: '100%', md: '350px' }}
                    maxWidth={{ base: '100%', md: '768px' }}
                    whiteSpace="pre-wrap"
                  >
                    <Text as="span" fontWeight="bold">
                      {item.external_id}
                    </Text>
                    . {item.description}
                  </Text>
                ),
                actions: (
                  <Button
                    colorScheme="green"
                    leftIcon={<Icon as={CheckIcon} />}
                    onClick={() => handleConfirm(item)}
                  >
                    Selecionar
                  </Button>
                ),
              })}
              data={unitConstructiveList}
              noDataMessage="Nenhuma unidade construtiva encontrada. Digite um código para pesquisar."
              loading={loading}
              onUpdateSort={() => {
                //
              }}
            />

            <PaginationWrapper
              lastPage={servicePagination.last_page}
              onPaginate={(selectedPage) => {
                setPagination({ ...pagination, page: selectedPage });
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

export default ModalUnitConstructiveLookup;
