import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';

import { Check as CheckIcon } from '@styled-icons/boxicons-regular/Check';
import { Globe as GlobeIcon } from '@styled-icons/boxicons-regular/Globe';
import { Close as CloseIcon } from '@styled-icons/evaicons-solid/Close';
import { api } from 'services/api';
import { formatDate } from 'shared/date';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Heading,
  Progress,
  HStack,
  Text,
  Box,
  useMediaQuery,
  Select,
  SimpleGrid,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';
import PaginationWrapper from 'components/Pagination';
import SummaryCard from 'components/SummaryCard';
import SummaryContainer from 'components/SummaryContainer';

import useThrottledState from 'hooks/useThrottledState';

import { Import } from 'types/import';
import { Pagination, ServicePagination } from 'types/pagination';

import ImportPreviewTable from './ImportPreviewTable';
import { ImportPreview } from './types';

type Status = 'all' | 'error' | 'warning' | 'success';

interface ModalImportProps extends ModalRootProps {
  data: {
    importId: number;
  };
}

const modalMargin = 120;
const modalMobileFix = 100;

const ModalImport: React.FC<ModalImportProps> = ({
  data: { importId },
  handleClose,
  ...restProps
}) => {
  const [isMobile] = useMediaQuery('(max-width: 728px)');

  const [refetchInterval, setRefetchInterval] = useState(1000 * 1); // 1 second
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  const scrollingBody = useRef<HTMLDivElement>(null);
  const targetElement = useRef<HTMLDivElement>(null);

  const [status, setStatus] = useState<Status>('all');

  const [pagination, setPagination] = useThrottledState<Pagination>(
    (() => {
      return {
        per_page: 20,
        page: 1,
      };
    })(),
    1000,
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const [importPreview, setImportPreview] = useState<ImportPreview[]>([]);
  const [summary, setSummary] = useState({
    total: 0,
    success: 0,
    error: 0,
  });

  const getImport = useCallback(async () => {
    const { data: responseData } = await api.get(`/import/${importId}`);
    return responseData.data as Import;
  }, [importId]);

  const getImportPreview = useCallback(async () => {
    setLoading(true);
    try {
      const { data: responseData } = await api.get(
        `/import/${importId}/preview`,
        {
          params: {
            page: pagination.page,
            per_page: pagination.per_page,
            'filter[status]': status === 'all' ? undefined : status,
            'filter[_index]': 'import_element.own_composition',
            'filter[composition_code]': 'NULL',
          },
        },
      );
      setImportPreview(responseData.data);
      setSummary({
        total: responseData.meta.total,
        success: responseData.summary?.status?.success || 0,
        error: responseData.summary?.status?.error || 0,
      });
      setServicePagination({
        last_page: responseData.meta.last_page,
      });
    } catch (err) {
      toast({
        description: 'Houve um erro ao obter o preview do arquivo.',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [importId, pagination, status]);

  const queryImport = useQuery(['Import', importId], getImport, {
    refetchInterval,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (
        data.progress_bar?.progress_percent &&
        data.progress_bar?.progress_percent >= 100
      ) {
        setRefetchInterval(1000 * 30); // 30 seconds
      }
    },
  });

  // const queryPreview = useQuery(['ImportPreview', importId], getImportPreview, {
  //   refetchInterval,
  //   refetchOnWindowFocus: false,
  // });

  const isImporting =
    (queryImport.data?.progress_bar?.progress_percent || 0) < 100;

  useEffect(() => {
    if (!isImporting) {
      getImportPreview();
    }
  }, [getImportPreview, isImporting]);

  useEffect(() => {
    if (pagination.page > servicePagination?.last_page) {
      setPagination((oldPagination) => {
        if (oldPagination.page > 1) {
          return {
            ...oldPagination,
            page: 1,
          };
        }

        return oldPagination;
      });
    }
  }, [pagination.page, servicePagination, setPagination]);

  const handleConfirm = async (): Promise<void> => {
    if (scrollingBody.current && targetElement.current) {
      scrollingBody.current.scrollTo({
        top: targetElement.current.offsetTop - 500,
        behavior: 'smooth',
      });
    }

    try {
      setSaving(true);
      await api.post(`/import/${importId}/persist`);

      toast({
        description: 'Os dados serão salvos na base.',
        status: 'success',
      });

      setRefetchInterval(1000 * 1); // 1 second

      // handleClose();
    } catch (err) {
      toast({
        description:
          err.response?.data?.message ||
          'Houve um erro inesperado ao importar o arquivo.',
        status: 'error',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal scrollBehavior="inside" {...restProps}>
      <ModalOverlay />
      <ModalContent
        sx={{
          width: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          maxWidth: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          height: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          maxHeight: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
        }}
      >
        <ModalHeader>Resumo do processamento</ModalHeader>

        {queryImport.data?.version && (
          <ModalHeader
            mt="-6"
            fontSize="md"
            fontStyle="italic"
            display="flex"
            gap={2}
          >
            <Text>Base: {queryImport.data.version.base.description}</Text>
            <Text>/</Text>
            <Text>Referência: {queryImport.data.version.description}</Text>
          </ModalHeader>
        )}

        <ModalCloseButton />

        <ModalBody ref={scrollingBody}>
          <SummaryContainer loading={loading}>
            <SummaryCard
              idx={1}
              isHidden={!summary.total}
              typeNumber="number"
              data={{
                icon: GlobeIcon,
                title: 'TOTAL',
                number: summary.total || 0,
                formattedNumber: Intl.NumberFormat('pt-BR').format(
                  summary.total || 0,
                ),
                colorSchemeCard: 'gray',
              }}
            />

            <SummaryCard
              idx={1}
              isHidden={!summary.success}
              typeNumber="number"
              data={{
                icon: CheckIcon,
                title: 'SUCESSOS',
                number: summary.success || 0,
                formattedNumber: Intl.NumberFormat('pt-BR').format(
                  summary.success || 0,
                ),
                colorSchemeCard: 'green',
              }}
            />

            <SummaryCard
              idx={1}
              isHidden={!summary.error}
              typeNumber="number"
              data={{
                icon: CloseIcon,
                title: 'ERROS',
                number: summary.error || 0,
                formattedNumber: Intl.NumberFormat('pt-BR').format(
                  summary.error || 0,
                ),
                colorSchemeCard: 'red',
              }}
            />
          </SummaryContainer>

          <Heading
            ref={targetElement}
            as="h3"
            fontWeight="medium"
            fontSize="lg"
            mb={2}
          >
            {queryImport.data?.file &&
              `Arquivo: "${queryImport.data?.file.name}"`}
          </Heading>

          {queryImport.data?.progress_bar && (
            <Text>
              {isImporting ? 'Importação iniciada' : 'Importação realizada'}{' '}
              {queryImport.data?.progress_bar?.elapsed_for_humans} (
              {formatDate(queryImport.data?.progress_bar?.start_time_iso)})
            </Text>
          )}

          {isImporting && (
            <Progress
              hasStripe
              isAnimated
              value={queryImport.data?.progress_bar?.progress_percent || 0}
              mt={4}
            />
          )}

          {isImporting && (
            <HStack mt={2}>
              <Text fontSize="sm" display="flex">
                <Text fontWeight="bold">Tempo restante</Text>:{' '}
                {queryImport.data?.progress_bar?.remaining_for_humans}
              </Text>

              <Text fontSize="sm" display="flex">
                <Text fontWeight="bold">Tempo estimado</Text>:{' '}
                {queryImport.data?.progress_bar?.estimated_for_humans}
              </Text>
            </HStack>
          )}

          <Box marginTop="20px">
            <SimpleGrid columns={{ base: 1, md: 2 }} mt={6}>
              <HStack mb={3}>
                <Text fontSize="smaller">Por página:</Text>
                <Select
                  width="auto"
                  defaultValue={pagination.per_page}
                  onChange={(e) => {
                    setPagination({
                      ...pagination,
                      per_page: Number(e.target.value),
                    });
                  }}
                >
                  {[5, 10, 20, 50, 100].map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </HStack>

              <HStack mb={3} display="flex" justifyContent="flex-end">
                <Text fontSize="smaller">Status:</Text>
                <Select
                  width="auto"
                  defaultValue={pagination.per_page}
                  onChange={(e) => {
                    setStatus(e.target.value as Status);
                  }}
                >
                  {[
                    ['all', 'Todos'],
                    ['success', 'Sucesso'],
                    ['warning', 'Atenção'],
                    ['error', 'Erro'],
                  ].map(([key, description]) => (
                    <option key={key} value={key}>
                      {description}
                    </option>
                  ))}
                </Select>
              </HStack>
            </SimpleGrid>

            <ImportPreviewTable
              importId={importId}
              data={importPreview}
              loading={loading}
            />

            <PaginationWrapper
              lastPage={servicePagination.last_page}
              onPaginate={(selectedPage) => {
                setPagination({ ...pagination, page: selectedPage });
              }}
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button isDisabled={loading || saving} onClick={handleClose}>
              Fechar
            </Button>

            <Button
              isLoading={saving}
              isDisabled={loading || saving}
              colorScheme="green"
              onClick={handleConfirm}
              disabled={isImporting || queryImport.data?.status === 'success'}
            >
              Consolidar importação (salvar dados na base)
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalImport;
