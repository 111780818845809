export default {
  brand: {
    50: '#698b95',
    100: '#507783',
    200: '#376471',
    300: '#1e5060',
    400: '#053d4e',
    500: '#053746',
    600: '#04313e',
    700: '#042b37',
    800: '#03252f',
    900: '#031f27 ',
  },
  siengeRed: {
    50: '#FDF2F1',
    100: '#FADBD9',
    200: '#F3ACA7',
    300: '#EC7C76',
    400: '#E64A44',
    500: '#D5211D',
    600: '#C31C1B',
    700: '#B11819',
    800: '#9F1619',
    900: '#8D1318',
  },
  siengeBlue: {
    50: '#F2F5FC',
    100: '#CCD7F4',
    200: '#809BE4',
    300: '#345DD5',
    400: '#1E398F',
    500: '#0E1A43',
    600: '#0A1332',
    700: '#0A1332',
    800: '#070C21',
    900: '#000000',
  },
  purple: {
    100: '#BD8BF0',
    200: '#B77FEE',
    300: '#B074ED',
    400: '#AA68EB',
    500: '#A55EEA',
    600: '#9848E7',
    700: '#8C33E5',
    800: '#801FE2',
    900: '#751BCE',
  },
  pink: {
    100: '#F0758B',
    200: '#EF6780',
    300: '#EE5974',
    400: '#EC4B69',
    500: '#EB3B5A',
    600: '#E92A4D',
    700: '#E5183E',
    800: '#D21639',
    900: '#BF1433',
  },
  yellow: {
    100: '#FEDE6A',
    200: '#FEDB5C',
    300: '#FED84D',
    400: '#FED43E',
    500: '#FED330',
    600: '#FECC1A',
    700: '#FEC804',
    800: '#A78301',
    900: '#896B01',
  },
  green: {
    100: '#24FF7C',
    200: '#0FFF6F',
    300: '#00F863',
    400: '#00E25A',
    500: '#00CD52',
    600: '#00BD4C',
    700: '#00AF46',
    800: '#005020',
    900: '#00421A',
  },
  lime: {
    50: '#f2ffde',
    100: '#defcb2',
    200: '#caf884',
    300: '#b5f554',
    400: '#a1f226',
    500: '#88d90d',
    600: '#69a905',
    700: '#4a7801',
    800: '#2b4800',
    900: '#0b1900',
  },
  blue: {
    100: '#4992FF',
    200: '#3787FF',
    300: '#247CFF',
    400: '#1271FF',
    500: '#0066FF',
    600: '#0057DB',
    700: '#0049B6',
    800: '#003A92',
    900: '#002C6D',
  },
  oceanBlue: {
    100: '#CAF0F8',
    200: '#ADE8F4',
    300: '#90E0EF',
    400: '#48CAE4',
    500: '#00B4D8',
    600: '#0096C7',
    700: '#0077B6',
    800: '#023E8A',
    900: '#03045E',
  },
  gray: {
    // 50: '#F7FAFC',
    50: '#F8F9FA',
    100: '#E9ECEF',
    200: '#DEE2E6',
    300: '#CED4DA',
    400: '#ADB5BD',
    500: '#A5B1C2',
    600: '#6C757D',
    700: '#495057',
    800: '#343A40',
    900: '#212529',
  },
  indigo: {
    50: '#eef2ff',
    100: '#e0e7ff',
    200: '#c7d2fe',
    300: '#a5b4fc',
    400: '#818cf8',
    500: '#6366f1',
    600: '#4f46e5',
    700: '#4338ca',
    800: '#3730a3',
    900: '#312e81',
  },
};
