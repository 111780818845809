import React from 'react';

import useAlert, { AlertProps } from 'hooks/useAlert';

import alertList from './alertList';

export interface AlertRootProps extends AlertProps {
  handleClose: () => void;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
}

export type AlertKey = keyof typeof alertList;

const AlertRoot: React.FC = () => {
  const { alerts, closeAlert } = useAlert();

  return (
    <>
      {alerts.map(({ key, props }) => {
        const Component = alertList[key];

        if (!Component) return null;

        return (
          <Component
            key={key}
            isOpen
            isCentered
            onClose={() => closeAlert(key)}
            handleClose={() => closeAlert(key)}
            closeOnOverlayClick={false}
            {...props}
          />
        );
      })}
    </>
  );
};

export default AlertRoot;
