import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Box,
  Radio,
  Text,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

type BudgetType = 'sienge_plataform' | 'common';

const ModalBudgetTypeSelect: React.FC<ModalRootProps> = ({
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const [type, setType] = React.useState<BudgetType>('sienge_plataform');

  const handleConfirm = async (): Promise<void> => {
    if (onConfirm) {
      onConfirm(type);
    }

    handleClose();
  };

  return (
    <Modal size="xl" scrollBehavior="inside" {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Novo orçamento</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text fontSize="md" fontWeight="500" marginBottom="4">
            <Text mb="4">Selecione o tipo de orçamento que deseja criar.</Text>

            <Text>
              Se desejar integrar seu orçamento com o Sienge Plataforma,
              selecione a opção &quot;Orçamento Sienge&quot;.
            </Text>
          </Text>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            maxWidth={480}
            gap="4"
          >
            <Box
              aria-selected={type === 'sienge_plataform'}
              tabIndex={0}
              padding={4}
              borderRadius="md"
              border="1px solid"
              borderColor="gray.200"
              cursor="pointer"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              gap="4"
              _selected={{
                bg: 'blue.50',
                borderColor: 'blue.400',
              }}
              onClick={() => {
                setType('sienge_plataform');
              }}
            >
              <Radio
                size="lg"
                colorScheme="blue"
                isChecked={type === 'sienge_plataform'}
              />
              <Text fontSize="xl" fontWeight="500">
                Orçamento Sienge
              </Text>
            </Box>

            <Box
              aria-selected={type === 'common'}
              tabIndex={0}
              padding={4}
              borderRadius="md"
              border="1px solid"
              borderColor="gray.200"
              cursor="pointer"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              gap="4"
              _selected={{
                bg: 'blue.50',
                borderColor: 'blue.400',
              }}
              onClick={() => {
                setType('common');
              }}
            >
              <Radio
                size="lg"
                colorScheme="blue"
                isChecked={type === 'common'}
              />
              <Text fontSize="xl" fontWeight="500">
                Orçamento padrão
              </Text>
            </Box>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="green" onClick={handleConfirm}>
            Criar orçamento
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalBudgetTypeSelect;
