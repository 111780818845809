import React, { memo, useState, useEffect, useCallback } from 'react';

import classNames from 'classnames';
import SortAlphabeticalAscendingIcon from 'mdi-react/SortAlphabeticalAscendingIcon';
import SortAlphabeticalDescendingIcon from 'mdi-react/SortAlphabeticalDescendingIcon';
import SortNumericAscendingIcon from 'mdi-react/SortNumericAscendingIcon';
import SortNumericDescendingIcon from 'mdi-react/SortNumericDescendingIcon';

import { TableContainer, Icon } from '@chakra-ui/react';

import Loader from 'components/Loader';
import { Table, Thead, Tbody, Tr, Th, Td } from 'components/Table';

type OnClick = React.MouseEvent<HTMLTableCellElement, globalThis.MouseEvent>;

type Row = {
  field: string;
  description: Data;
  sortable?: boolean;
  condition?: boolean;
  isNumeric?: boolean;
  isCentered?: boolean;
};

type KeyExtractor = { id: string | number };
type ActionColumn = { actions: JSX.Element | null };
type TableRows = Array<Row>;

type Data =
  | string
  | number
  | boolean
  | Date
  | typeof Function
  | JSX.Element
  | null;
type TableData = { [key: string]: Data };

type MappingFunction<T> = (item: T) => TableData & KeyExtractor & ActionColumn;

interface TableProps<T> {
  cols: TableRows;
  mapping: MappingFunction<T>;
  data: Array<T>;
  noDataMessage?: string;
  initialSort?: string;
  onUpdateSort?: (sort: string) => void;
  loading?: boolean;
}

export function createColumns(cols: Array<Row>): Array<Row> {
  return cols.filter((col) => {
    if ('condition' in col) {
      return col.condition;
    }

    return true;
  });
}

const AppTable = <T extends unknown>({
  cols,
  mapping,
  data = [],
  noDataMessage = 'Nenhum resultado encontrado. Atualize os filtros e pequise novamente',
  initialSort = '',
  onUpdateSort,
  loading = false,
}: TableProps<T>): React.ReactElement => {
  const [sort, setSort] = useState<string>(initialSort);
  const [rows, setRows] = useState<TableData[]>([]);

  useEffect(() => {
    const mappingObject = data.map((d) => mapping(d));

    setRows(mappingObject);
  }, [data, mapping]);

  const isReactNode = useCallback(
    ({ field }: Row) => typeof field === 'function',
    [],
  );

  const isActionsColumn = useCallback(
    ({ field }: Row) => typeof field === 'string' && field === 'actions',
    [],
  );

  const getColumnIcon = useCallback(
    (column: Row): React.ReactNode => {
      const allFields = sort.split(',');

      const fieldExists = allFields.some(
        (f) => f === column.field || f === `-${column.field}`,
      );
      const field = allFields.find((f) => f.includes(column.field));
      const index = allFields.findIndex((f) => f.includes(column.field));

      if (fieldExists && index >= 0) {
        if (!isActionsColumn(column)) {
          return field?.startsWith('-') ? (
            <Icon
              as={
                column.isNumeric
                  ? SortNumericDescendingIcon
                  : SortAlphabeticalDescendingIcon
              }
              mx="1"
              w={5}
              h={5}
            />
          ) : (
            <Icon
              as={
                column.isNumeric
                  ? SortNumericAscendingIcon
                  : SortAlphabeticalAscendingIcon
              }
              mx="1"
              w={5}
              h={5}
            />
          );
        }
      }

      return null;
    },
    [sort, isActionsColumn],
  );

  const handleSortChange = useCallback(
    (event: OnClick, row: Row) => {
      const allFields = sort.split(',');
      const existsField = allFields.findIndex((f) => f.includes(row.field));

      if (existsField === -1) {
        if (event.shiftKey) {
          const newSort = sort
            .concat(`,${row.field}`)
            .replace(/^,/, '')
            .replace(/^-/, '');

          setSort(newSort);
          onUpdateSort?.(newSort);
        } else {
          setSort(row.field);
          onUpdateSort?.(row.field);
        }

        return;
      }

      const field = allFields.find((f) => f.includes(row.field));

      const newSortArray = allFields;

      if (existsField >= 0 && field) {
        const index = allFields.indexOf(field);
        newSortArray[index] = `-${field}`;
      }

      if (existsField >= 0 && field?.startsWith('-')) {
        const index = allFields.indexOf(field);
        newSortArray.splice(index, 1);
      }

      const newSort = newSortArray.join(',');
      setSort(newSort);
      onUpdateSort?.(newSort);
    },
    [sort, onUpdateSort],
  );

  const handleOnClick = useCallback(
    (col: Row) => {
      return (event: OnClick) => {
        return (
          !isReactNode(col) &&
          !isActionsColumn(col) &&
          !('sortable' in col && col.sortable === false) &&
          typeof col.description !== 'object' &&
          handleSortChange(event, col)
        );
      };
    },
    [handleSortChange, isActionsColumn, isReactNode],
  );

  const getClassNames = useCallback(
    (col) => {
      return classNames({
        clickable:
          !isActionsColumn(col) &&
          !('sortable' in col && col.sortable === false) &&
          typeof col.description !== 'object',

        selected:
          !isActionsColumn(col) &&
          sort.split(',').some((f) => f === col.field || f === `-${col.field}`),
      });
    },
    [isActionsColumn, sort],
  );

  const shouldCenter = useCallback(
    (col: Row) => {
      if (isActionsColumn(col)) return true;
      return col.isCentered;
    },
    [isActionsColumn],
  );

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr inHeader>
            {cols.map((col) => {
              return (
                <Th
                  key={col.field}
                  isNumeric={col.isNumeric}
                  onClick={handleOnClick(col)}
                  className={getClassNames(col)}
                  textAlign={shouldCenter(col) ? 'center' : undefined}
                >
                  {typeof col.description !== 'string' &&
                  typeof col.description !== 'number' &&
                  typeof col.description !== 'boolean' &&
                  typeof col.description !== 'object' &&
                  !(col.description instanceof Date)
                    ? col.description()
                    : col.description}

                  {!isReactNode(col) ? getColumnIcon(col) : null}
                </Th>
              );
            })}
          </Tr>
        </Thead>

        <Tbody>
          {!loading &&
            rows.map((row) => {
              const rowKeys = Object.keys(row);
              const key = row.id;

              if (typeof key !== 'number' && typeof key !== 'string') {
                const error = `Invalid row key. You need an alphanumeric id in the mapping object.`;
                throw new Error(error);
              }

              const active = rowKeys.includes('active') && row.active === true;
              const deleted =
                rowKeys.includes('deleted') && row.deleted === true;

              const mapTd = cols.map((col, index) => {
                const value = row[col.field];

                return (
                  <Td
                    key={col.field}
                    columnKey={index}
                    isNumeric={col.isNumeric}
                    textAlign={shouldCenter(col) ? 'center' : undefined}
                    className={getClassNames(col)}
                    onClick={handleOnClick(col)}
                  >
                    {value || '-'}
                  </Td>
                );
              });

              return (
                <Tr
                  key={key}
                  inHeader={false}
                  className={classNames({
                    highlighted: active,
                    deleted,
                  })}
                >
                  {mapTd}
                </Tr>
              );
            })}

          {loading && (
            <Tr inHeader={false}>
              <Td columnKey={1} colSpan={10}>
                <Loader />
              </Td>
            </Tr>
          )}

          {!loading && data.length === 0 && (
            <Tr inHeader={false}>
              <Td columnKey={1} colSpan={10} className="text-center">
                {noDataMessage}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default memo(AppTable) as typeof AppTable;
