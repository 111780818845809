import React from 'react';

import { stateDescriptionByKey } from 'shared/locale';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  TableContainer,
  Text,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';
import { Table, Thead, Tbody, Tr, Th, Td } from 'components/Table';

interface ModalInputPrices extends ModalRootProps {
  data: {
    inputs_description: string;
    prices: string;
  };
}

type Prices = {
  taxed: { total: number };
  untaxed: { total: number };
};

type PricesMap = {
  locale: string;
  taxed_total: number | null;
  untaxed_total: number | null;
};

function sortLocales(a: PricesMap, b: PricesMap): number {
  if (a.locale < b.locale) return -1;
  if (a.locale > b.locale) return 1;
  return 0;
}

const ModalInputPricesLookup: React.FC<ModalInputPrices> = ({
  data,
  ...restProps
}) => {
  const mappedPrices = (() => {
    const prices = JSON.parse(data.prices);
    const entries = Object.entries<Prices>(prices);

    const pricesMap = entries
      .map(([locale, price]) => {
        return {
          locale: stateDescriptionByKey[locale],
          taxed_total: price.taxed ? price.taxed.total : null,
          untaxed_total: price.untaxed ? price.untaxed.total : null,
        };
      })
      .sort(sortLocales);

    return pricesMap;
  })();

  return (
    <Modal {...restProps} size="2xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Preços da composição</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text mt={-3} fontSize="sm" fontWeight="600">
            {data.inputs_description}
          </Text>

          <TableContainer mt={5}>
            <Table>
              <Thead>
                <Tr>
                  <Th>Estado</Th>
                  <Th>Desonerado</Th>
                  <Th>Não desonerado</Th>
                </Tr>
              </Thead>
              <Tbody>
                {mappedPrices.map((item, i) => (
                  <Tr key={item.locale}>
                    <Td columnKey={i}>{item.locale}</Td>
                    <Td columnKey={i}>
                      {item.untaxed_total
                        ? Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(item.untaxed_total)
                        : null}
                    </Td>
                    <Td columnKey={i}>
                      {item.taxed_total
                        ? Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(item.taxed_total)
                        : null}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalInputPricesLookup;
