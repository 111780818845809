import React, { useCallback, useState } from 'react';
import InputNumberFormat from 'react-number-format';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Flex,
  Grid,
  FormControl,
  FormLabel,
  Input,
  HStack,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

const ModalABCValues: React.FC<ModalRootProps> = ({
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const [valueA, setValueA] = useState(50);
  const [valueB, setValueB] = useState(80);
  const [valueC, setValueC] = useState(100);

  const handleSubmitValues = useCallback(async () => {
    if (onConfirm) onConfirm(valueA, valueB, valueC);
    handleClose();
  }, [handleClose, onConfirm, valueA, valueB, valueC]);

  return (
    <Modal {...restProps} size="2xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Curva ABC</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Flex
            w="100%"
            as="form"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            sx={{
              '> *': {
                marginY: 1,
              },
            }}
          >
            <Grid
              w="100%"
              templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
              columnGap={2}
              rowGap={4}
            >
              <FormControl>
                <FormLabel>Índice A</FormLabel>

                <InputNumberFormat
                  type="tel"
                  customInput={Input}
                  displayType="input"
                  className="text-right"
                  placeholder="0 %"
                  fixedDecimalScale
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" %"
                  decimalScale={2}
                  value={valueA}
                  onValueChange={(v) => {
                    setValueA(v.floatValue || 0);
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Índice B</FormLabel>

                <InputNumberFormat
                  type="tel"
                  customInput={Input}
                  displayType="input"
                  className="text-right"
                  placeholder="0 %"
                  fixedDecimalScale
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" %"
                  decimalScale={2}
                  value={valueB}
                  onValueChange={(v) => {
                    setValueB(v.floatValue || 0);
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Índice C</FormLabel>

                <InputNumberFormat
                  type="tel"
                  customInput={Input}
                  displayType="input"
                  className="text-right"
                  placeholder="0 %"
                  fixedDecimalScale
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" %"
                  decimalScale={2}
                  value={valueC}
                  onValueChange={(v) => {
                    setValueC(v.floatValue || 0);
                  }}
                />
              </FormControl>
            </Grid>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button colorScheme="green" onClick={handleSubmitValues}>
              Confirmar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalABCValues;
